import { Button } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import * as React from "react"
import { Redirect } from "react-router-dom"
import { Page } from "../../components"
import {
  ActionButtonContainer,
  FieldGroup,
  PageHeader,
  ReadOnlyForm,
  ReadOnlyTextField,
} from "../../components/common"
import { useModal } from "../../components/common/utils/modal"
import EditUserForm from "../../components/users/EditUserForm"
import { Paths } from "../../constants/routes"
import { useStore } from "../../getMstGql"

const ProfilePage: React.FC = () => {
  const {
    authentication: { currentUser },
  } = useStore()
  const { openDrawer, closeDrawer } = useModal()
  if (!currentUser) {
    return <Redirect to={Paths.Login} />
  }
  return (
    <Page
      header={
        <PageHeader title="Profile" breadcrumbs={[{ label: currentUser.name || "" }]}>
          <ActionButtonContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                openDrawer(<EditUserForm user={currentUser} profile onComplete={closeDrawer} />)
              }
            >
              Edit
            </Button>
          </ActionButtonContainer>
        </PageHeader>
      }
    >
      <ReadOnlyForm title="General">
        <FieldGroup>
          <ReadOnlyTextField label="Email" value={currentUser.email} />
        </FieldGroup>
        <FieldGroup>
          <ReadOnlyTextField label="Name" value={currentUser.name} />
        </FieldGroup>
      </ReadOnlyForm>
    </Page>
  )
}

export default observer(ProfilePage)
