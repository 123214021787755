import { Button } from "@material-ui/core"
import { Formik, FormikProps } from "formik"
import * as React from "react"
import * as Yup from "yup"
import { useStore } from "../../getMstGql"
import { EmailModelType } from "../../models"
import { displayMutationError, hasMutationErrors } from "../../utilities/errors"
import { useToast } from "../common"
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormTextField,
} from "../common/forms"

type RecordType = EmailModelType

function getInitialValues(record?: RecordType) {
  return {
    email: record?.email ?? "",
  }
}

const schema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
})

export interface EmailFormProps {
  onComplete: (updated?: boolean) => any
  vetId: string
  record?: RecordType
}

const EmailForm: React.FC<EmailFormProps> = ({ onComplete, vetId, record }) => {
  const store = useStore()
  const { setToast } = useToast()

  return (
    <FormContainer title={record ? "Edit Address" : "Add Address"}>
      <Formik
        initialValues={getInitialValues(record)}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          try {
            actions.setSubmitting(true)

            const response = record
              ? await store
                  .mutateUpdateEmail({
                    id: record.id,
                    input: {
                      email: values.email,
                      vetId,
                    },
                  })
                  .currentPromise()
              : await store
                  .mutateCreateEmail({
                    input: {
                      email: values.email,
                      vetId,
                    },
                  })
                  .currentPromise()

            if (hasMutationErrors(response)) {
              setToast(displayMutationError(response))
              return
            }

            setToast({
              message: `Email has been ${record ? "updated" : "added"}!`,
              variant: "success",
            })
            actions.resetForm()
            onComplete(true)
          } finally {
            actions.setSubmitting(false)
          }
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="">
              <FieldGroup>
                <FormTextField label="Email" name="email" />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                onClick={() => {
                  return props.submitForm()
                }}
              >
                Save
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default EmailForm
