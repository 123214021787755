import { addressModelPrimitives } from "./AddressModel"
import { aliasModelPrimitives } from "./AliasModel"
import { emailModelPrimitives } from "./EmailModel"
import { FlagCreationResponseModelSelector } from "./FlagCreationResponseModel.base"
import { selectFromFlagListResponse } from "./FlagListResponseModel"
import { FlagModelSelector, selectFromFlag } from "./FlagModel"
import { phoneNumberModelPrimitives } from "./PhoneNumberModel"
import { tagModelPrimitives } from "./TagModel"
import { selectFromVetListResponse } from "./VetListResponseModel"
import { selectFromVet } from "./VetModel"

export const flagSelector = selectFromFlag()
  .createdAt.updatedAt.customSource.title.text.image.url.completedAt.source.vetId.clients.tags(
    tagModelPrimitives,
  )
  .vet((sel) => sel.id.name)
  .toString()

export const flagsListSelector = selectFromFlagListResponse().records(flagSelector).count.toString()

export const flagCreationResponseSelector = new FlagCreationResponseModelSelector().message.success
  .flag((sel: FlagModelSelector) => sel.id)
  .toString()

export const vetSelector = selectFromVet()
  .id.firstName.lastName.middleName.title.name.flagCount.entityType.clientIds.flags(flagSelector)
  .addresses(addressModelPrimitives)
  .emails(emailModelPrimitives)
  .aliases(aliasModelPrimitives)
  .phoneNumbers(phoneNumberModelPrimitives)
  .toString()

export const vetsListSelector = selectFromVetListResponse().count.records(vetSelector).toString()
