import { TextField } from "@material-ui/core"
import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from "@material-ui/core/TextField/TextField"
import * as React from "react"

export type FormattedTextFieldProps =
  | StandardTextFieldProps
  | FilledTextFieldProps
  | OutlinedTextFieldProps

const FormattedTextField: React.FC<FormattedTextFieldProps> = (props) => {
  return <TextField disabled InputLabelProps={{ shrink: !!props.value }} size="small" {...props} />
}

export default FormattedTextField
