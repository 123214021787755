import { Accordion, AccordionSummary, Typography } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import MaterialTable, { Column, Options, Query as TableQuery, QueryResult } from "material-table"
import { observer } from "mobx-react-lite"
import { Query } from "mst-gql"
import * as React from "react"
import { useStore } from "../../getMstGql"
import { RootStoreType, TagListResponseModelType, TagModelType, tagsSelector } from "../../models"
import { rowDatum } from "../../utilities/coercion"
import { extractPaginationArgs } from "../../utilities/tables"
import { useTableCacheKey } from "../common/utils/table/useTableCacheKey"

type QueryResponseType = { tags: TagListResponseModelType }

function buildQuery(
  store: RootStoreType,
  tableQuery: TableQuery<TagModelType>,
): Query<QueryResponseType> {
  return store.queryTags(
    {
      pagination: extractPaginationArgs(tableQuery),
    },
    tagsSelector,
  )
}

export type TagsTableProps = {
  filters?: {}
  tableOptions?: Options<TagModelType>
  onRowClick?: (row: TagModelType) => void
  cacheKey?: string | number
}

const TagsTable: React.FC<TagsTableProps> = ({ onRowClick, cacheKey, tableOptions = {} }) => {
  const store = useStore()
  const tableRef = useTableCacheKey(cacheKey)
  const handleDetailsClick = React.useCallback(
    (row: TagModelType): void => onRowClick && onRowClick(row),
    [onRowClick],
  )

  const handleTableUpdate = React.useCallback(
    async (query: TableQuery<TagModelType>): Promise<QueryResult<TagModelType>> => {
      const results = await buildQuery(store, query)
      return {
        data: results?.tags?.records?.toJS() || [],
        totalCount: results?.tags?.count || 0,
        page: query.page,
      }
    },
    [store],
  )

  const combinedTableOptions: Options<TagModelType> = React.useMemo(
    () => ({
      filtering: true,
      toolbar: false,
      debounceInterval: 300,
      pageSize: 10,
      ...tableOptions,
    }),
    [tableOptions],
  )

  const columns = React.useMemo(
    (): Array<Column<TagModelType>> => [
      {
        title: "Name",
        field: "name",
        filtering: true,
      },
    ],
    [],
  )

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Tags</Typography>
      </AccordionSummary>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={handleTableUpdate}
        options={combinedTableOptions}
        localization={{ header: { actions: "" } }}
        style={{ boxShadow: "none" }}
        actions={[
          {
            icon: "pageview",
            tooltip: "View details",
            onClick: (event, d): void => handleDetailsClick(rowDatum(d)),
          },
        ]}
      />
    </Accordion>
  )
}

export default observer(TagsTable)
