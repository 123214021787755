import * as React from "react"
import { useCallback, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  ActionButtonContainer,
  AddUserForm,
  Button,
  Page,
  PageHeader,
  useModal,
  UsersTable,
} from "src/components"
import { UserModelType } from "../../models"

const UsersListPage: React.FC = () => {
  const history = useHistory()
  const { openDrawer, closeDrawer } = useModal()
  const [cacheKey, setCacheKey] = useState(Date.now())
  const updateCacheKey = useCallback(() => setCacheKey(Date.now()), [setCacheKey])

  const handleAddUser = useCallback(
    (willNavigate?: boolean) => {
      closeDrawer()
      if (!willNavigate) {
        updateCacheKey()
      }
    },
    [closeDrawer, updateCacheKey],
  )

  const handleRowClick = React.useCallback(
    (row: UserModelType): void => {
      history.push(`/users/${row.id}`)
    },
    [history],
  )

  return (
    <Page
      header={
        <PageHeader title="Users" breadcrumbs={[{ label: "Users" }]}>
          <ActionButtonContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => openDrawer(<AddUserForm onComplete={handleAddUser} />)}
            >
              Create
            </Button>
          </ActionButtonContainer>
        </PageHeader>
      }
    >
      <UsersTable onRowClick={handleRowClick} cacheKey={cacheKey} />
    </Page>
  )
}

export default UsersListPage
