import { amber, teal } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"
import { CSSProperties } from "react"

const fullBodyProps: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  height: "auto",
  minHeight: "100vh",
}

export const defaultTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: teal,
    secondary: amber,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
          width: "100%",
          ...fullBodyProps,
        },
        body: fullBodyProps,
        "#root": fullBodyProps,
      },
    },
  },
})
