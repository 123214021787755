import { Box, Button, Container, makeStyles, Theme } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { Layout, ModalProvider, OnlyLoggedOutRoute, PrivateRoute, ToastProvider } from "src/components"
import { Paths, POST_LOGIN_PATH } from "./constants/routes"
import { useStore } from "./getMstGql"
import { UserRole } from "./models"
import * as Pages from "./pages"
import { isDevMode } from "./utilities/env"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    justifyContent: "center",
    alignContent: "center",
    marginTop: theme.spacing(16),
  },
  agreement: {
    backgroundColor: theme.palette.background.paper,
    margin: "auto",
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}))

const LoggedInRoutes: React.FC = () => {
  const styles = useStyles()
  const [acceptedAgreement, setAcceptedAgreement] = useState(isDevMode())
  const {
    logout,
    authentication: { currentUser },
  } = useStore()

  if (!currentUser) {
    return <Redirect to={Paths.Login} />
  }

  if (!acceptedAgreement) {
    return (
      <Container maxWidth="xs" className={styles.container}>
        <Box className={styles.agreement}>
          <Box>
            IN ACCORDANCE TO THE FAIR CREDIT REPORTING ACT, DO NOT USE THIS DATABASE IF CONDUCTING
            AN EMPLOYMENT BACKGROUND CHECK
          </Box>
          <Box className={styles.buttonContainer}>
            <Button
              variant="contained"
              onClick={() => {
                logout()
              }}
            >
              Logout
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setAcceptedAgreement(true)
              }}
            >
              Agree
            </Button>
          </Box>
        </Box>
      </Container>
    )
  }

  return (
    <Layout>
      <ToastProvider>
        <ModalProvider>
          <Switch>
            <PrivateRoute path={Paths.UserProfile} component={Pages.UserProfilePage} />

            <PrivateRoute path={Paths.VetCreate} component={Pages.VetDetailsPage} />
            <PrivateRoute path={Paths.VetDetails} component={Pages.VetDetailsPage} />
            <PrivateRoute path={Paths.VetsList} component={Pages.VetsListPage} />

            <PrivateRoute path={Paths.ClientDetails} component={Pages.ClientDetailsPage} />
            <PrivateRoute path={Paths.ClientsList} component={Pages.ClientsListPage} />

            <PrivateRoute path={Paths.FlagsList} component={Pages.FlagsListPage} />

            <PrivateRoute path={Paths.TagDetails} component={Pages.TagDetailsPage} />
            <PrivateRoute path={Paths.TagsList} component={Pages.TagsListPage} />

            <PrivateRoute path={Paths.UserDetails} component={Pages.UserDetailsPage} />
            <PrivateRoute
              path={Paths.UsersList}
              component={Pages.UsersListPage}
              requiredRole={UserRole.ADMIN}
            />

            <PrivateRoute path={Paths.ExportsList} component={Pages.ExportsListPage} />

            <Route path="*" component={(): JSX.Element => <Redirect to={POST_LOGIN_PATH} />} />
          </Switch>
        </ModalProvider>
      </ToastProvider>
    </Layout>
  )
}

const ObservedLoggedInRoutes = observer(LoggedInRoutes)

const Routes: React.FC = () => {
  return (
    <Switch>
      <OnlyLoggedOutRoute path={Paths.Login} component={Pages.Login} />
      <Route path="*" component={ObservedLoggedInRoutes} />
    </Switch>
  )
}

export default Routes
