import { Box, CircularProgress, IconButton, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import DeleteIcon from "@material-ui/icons/Delete"
import { Formik, FormikProps } from "formik"
import startCase from "lodash/startCase"
import { DateTime } from "luxon"
import { observer } from "mobx-react-lite"
import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"
import { useStore } from "../../getMstGql"
import {
  FlagModelType,
  FlagSource,
  TagListResponseModelType,
  TagModelType,
  tagsSelector,
} from "../../models"
import { useQuery } from "../../models/reactUtils"
import { CreateFlagInput } from "../../models/RootStore.base"
import { flagCreationResponseSelector } from "../../models/selectors"
import { displayMutationError, hasMutationErrors } from "../../utilities/errors"
import {
  Button,
  FieldGroup,
  FormButtonGroup,
  FormDateField,
  FormSection,
  FormSelectField,
  FormTextField,
  useToast,
} from "../common"
import { FormContainer } from "../common/forms"

const useStyles = makeStyles((theme: Theme) => ({
  dropzoneContainer: {
    borderWidth: "3px",
    borderStyle: "dashed",
    borderColor: theme.palette.divider,
    display: "flex",
    justifyContent: "center",
    padding: "32px 0px",
    cursor: "pointer",
  },
}))

function buildInitialFormValues(vet?: FlagModelType): Partial<CreateFlagInput> {
  return {
    title: vet?.title ?? undefined,
    text: vet?.text ?? undefined,
    url: vet?.url ?? undefined,
    tags: vet?.tags?.map((t) => t.id) ?? [],
    completedAt: vet?.completedAt ? DateTime.fromISO(vet.completedAt) : DateTime.utc(),
    source: (vet?.source as FlagSource) ?? undefined,
    customSource: vet?.customSource ?? undefined,
  }
}

const schema = Yup.object().shape({
  completedAt: Yup.object().required("Required"),
  title: Yup.string().required("Required"),
  text: Yup.string().required("Required"),
  url: Yup.string(),
  source: Yup.string().required("Required"),
  customSource: Yup.string().when("source", {
    is: (v) => v === FlagSource.Other || v === FlagSource.Nexis,
    then: Yup.string().required("Required"),
    otherwise: Yup.string(),
  }),
  tags: Yup.array(Yup.string()),
})

export interface AddFlagFormProps {
  onComplete: (willNavigate?: boolean) => any
  vetId: string
  flag?: FlagModelType
}

const AddFlagForm: React.FC<AddFlagFormProps> = ({ onComplete, vetId, flag }) => {
  const styles = useStyles()
  const history = useHistory()
  const [navigateAfterCreation, setNavigateAfterCreate] = useState(false)
  const store = useStore()
  const { setToast } = useToast()
  const [imageUrl, setImageUrl] = useState<string | undefined>(flag?.image ?? undefined)

  const editMode = useMemo(() => !!flag, [flag])

  const onDrop = useCallback(async (acceptedFiles: Array<File>) => {
    const file = acceptedFiles[0]
    if (file) {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onloadend = () => {
        if (typeof fileReader.result === "string") {
          setImageUrl(fileReader.result)
        }
      }
    }
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  })

  const { loading: tagsLoading, data: tagsData } = useQuery<{ tags: TagListResponseModelType }>(
    (s) => s.queryTags({}, tagsSelector),
  )

  const tags: Array<TagModelType> = useMemo(() => {
    return tagsData?.tags?.records ?? []
  }, [tagsData])

  const loading = useMemo(() => tagsLoading, [tagsLoading])
  if (loading) {
    return <CircularProgress />
  }

  return (
    <FormContainer title={editMode ? "Update Flag" : "Add Flag"}>
      <Formik
        initialValues={buildInitialFormValues(flag)}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)
          const input: CreateFlagInput = {
            completedAt: values.completedAt,
            image: imageUrl ?? undefined,
            source: (values.source ?? FlagSource.Facebook) as FlagSource,
            customSource: values.customSource,
            title: values.title ?? "",
            text: values.text ?? "",
            url: values.url ?? "",
            tags: values.tags ?? [],
            vetId,
          }

          const response = editMode
            ? await store
                .mutateUpdateFlag({ id: flag?.id ?? "", input }, flagCreationResponseSelector)
                .currentPromise()
            : await store.mutateCreateFlag({ input }, flagCreationResponseSelector).currentPromise()

          if (hasMutationErrors(response)) {
            setToast(displayMutationError(response))
            return
          }

          setToast({
            message: `Flag ${values.title} has been ${editMode ? "updated" : "created"}!`,
            variant: "success",
          })
          actions.resetForm()
          onComplete(navigateAfterCreation)
          if (navigateAfterCreation) {
            const vetId = (response as any)?.flagVet?.vet?.id
            history.push(`/vets/${vetId}`)
          }
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="">
              <FieldGroup>
                <FormDateField label="Date completed" name="completedAt" />
              </FieldGroup>
            </FormSection>
            <FormSection title="Content">
              <FieldGroup>
                <FormTextField label="URL" name="url" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Title" name="title" />
              </FieldGroup>
              <FieldGroup>
                <FormTextField label="Text" name="text" InputProps={{ rows: 5 }} multiline />
              </FieldGroup>
            </FormSection>
            <FormSection title="Source">
              <FieldGroup>
                <FormSelectField
                  label="Source"
                  name="source"
                  options={Object.values(FlagSource).map((value) => ({
                    value,
                    label: startCase(value),
                  }))}
                  clearable={false}
                />
              </FieldGroup>
              <FieldGroup
                hidden={
                  props.values.source !== FlagSource.Other &&
                  props.values.source !== FlagSource.Nexis
                }
              >
                <FormTextField label="Custom source" name="customSource" />
              </FieldGroup>
            </FormSection>
            <FormSection title="Tags">
              <FieldGroup>
                <FormSelectField
                  label="Tags"
                  name="tags"
                  options={tags.map((value) => ({
                    value: value.id,
                    label: value.name ?? "",
                  }))}
                  multiple
                  clearable={false}
                />
              </FieldGroup>
            </FormSection>

            <FormSection title="Image">
              <Box paddingTop={2}>
                {imageUrl ? (
                  <Box position="relative">
                    <Box position="absolute" top={0} right={0}>
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() => {
                          setImageUrl(undefined)
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <img src={imageUrl} style={{ maxWidth: "500px" }} alt="Uploaded" />
                  </Box>
                ) : (
                  <div {...getRootProps()} className={styles.dropzoneContainer}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop the file here ...</p>
                    ) : (
                      <p>Drag and drop a file or click here</p>
                    )}
                  </div>
                )}
              </Box>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color={"primary"}
                disabled={props.isSubmitting}
                onClick={() => {
                  setNavigateAfterCreate(false)
                  return props.submitForm()
                }}
              >
                Save
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default observer(AddFlagForm)
