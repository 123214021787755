import { Accordion, AccordionSummary, Box, Button, Typography } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import MaterialTable, {
  Action,
  Column,
  Options,
  Query as TableQuery,
  QueryResult,
} from "material-table"
import { observer } from "mobx-react-lite"
import { Query } from "mst-gql"
import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { FlagSourceLookup } from "src/utilities/flag-sources"
import { useStore } from "../../getMstGql"
import {
  ClientModelType,
  clientsSelector,
  FlagListResponseModelType,
  FlagModelType,
  FlagOrderColumns,
  MutationResponseModelType,
  RootStoreType,
  TagModelType,
  tagsSelector,
} from "../../models"
import { useQuery } from "../../models/reactUtils"
import { FlagFilters } from "../../models/RootStore.base"
import { flagsListSelector } from "../../models/selectors"
import { rowDatum } from "../../utilities/coercion"
import { formatDate } from "../../utilities/dates"
import { displayMutationError, hasMutationErrors } from "../../utilities/errors"
import {
  extractDateRangeFilter,
  extractEqualsFilter,
  extractInFilter,
} from "../../utilities/filters"
import { extractOrderByArgs, extractPaginationArgs } from "../../utilities/tables"
import {
  DateRangeFilter,
  MultiSelectFilter,
  SelectFilter,
  TableActions,
  useModal,
  useToast,
} from "../common"
import { useTableCacheKey } from "../common/utils/table/useTableCacheKey"
import GenerateExportForm from "../exports/GenerateExportForm"
import AddFlagForm from "./AddFlagForm"

type QueryResponseType = { flags: FlagListResponseModelType }

function buildQuery(
  store: RootStoreType,
  tableQuery: TableQuery<FlagModelType>,
  filters: FlagFilters,
): Query<QueryResponseType> {
  return store.queryFlags(
    {
      pagination: extractPaginationArgs(tableQuery),
      filters: filters,
      orderBy: extractOrderByArgs(tableQuery, FlagOrderColumns),
    },
    flagsListSelector,
  )
}

function extractFilters(
  tableQuery: TableQuery<FlagModelType>,
  baseFilters?: FlagFilters,
): FlagFilters {
  const mergedFilters: FlagFilters = {
    ...baseFilters,
  }

  extractEqualsFilter(tableQuery, "title", mergedFilters)
  extractEqualsFilter(tableQuery, "text", mergedFilters)
  extractEqualsFilter(tableQuery, "source", mergedFilters)
  extractEqualsFilter(tableQuery, "customSource", mergedFilters)
  extractEqualsFilter(tableQuery, "clients", mergedFilters, "clientId")
  extractInFilter(tableQuery, "tags", mergedFilters)
  extractDateRangeFilter(tableQuery, "completedAt", mergedFilters)

  return mergedFilters
}

export type VetsTableProps = {
  filters?: FlagFilters
  tableOptions?: Options<FlagModelType>
  onRowClick?: (row: FlagModelType) => void
  cacheKey?: string | number
  hideFields?: Array<string>
  defaultExportTitle?: string
  hideExport?: boolean
  actions?: React.ReactElement
  onRowUpdated?: (row: FlagModelType) => void
  showEdit?: boolean
  showDelete?: boolean
}

export function useTagLookup(): Record<string, string> {
  const { data } = useQuery((store) => store.queryTags({}, tagsSelector))
  return useMemo(() => {
    const innerLookup: Record<string, string> = {}
    data?.tags?.records?.forEach((t: TagModelType) => {
      innerLookup[t.id] = t.name ?? ""
    })
    return innerLookup
  }, [data])
}

export function useClientLookup(): Record<string, string> {
  const { data } = useQuery((store) => store.queryClients({}, clientsSelector))
  return useMemo(() => {
    const innerLookup: Record<string, string> = {}
    data?.clients?.records?.forEach((t: ClientModelType) => {
      innerLookup[t.id] = t.name ?? ""
    })
    return innerLookup
  }, [data])
}

const FlagsTable: React.FC<VetsTableProps> = ({
  onRowClick,
  cacheKey,
  tableOptions = {},
  hideFields = [],
  filters,
  defaultExportTitle,
  hideExport,
  actions,
  onRowUpdated,
  showEdit,
  showDelete,
}) => {
  const store = useStore()
  const { openDrawer, closeDrawer, openDialog } = useModal()
  const tableRef = useTableCacheKey(cacheKey)
  const history = useHistory()
  const tagLookup = useTagLookup()
  const clientLookup = useClientLookup()
  const { setToast } = useToast()
  const handleDetailsClick = React.useCallback(
    (row: FlagModelType): void => {
      onRowClick ? onRowClick(row) : history.push(`/vets/${row.id}`)
    },
    [history, onRowClick],
  )
  const [mergedFilters, setMergedFilters] = useState<FlagFilters>(filters ?? {})

  const handleTableUpdate = React.useCallback(
    async (query: TableQuery<FlagModelType>): Promise<QueryResult<FlagModelType>> => {
      const merged = extractFilters(query, filters)
      setMergedFilters(merged)
      const results = await buildQuery(store, query, merged)
      return {
        data: results?.flags?.records?.toJS() || [],
        totalCount: results?.flags?.count || 0,
        page: query.page,
      }
    },
    [filters, store],
  )

  const handleExportComplete = useCallback(() => {
    closeDrawer()
  }, [closeDrawer])

  const handleExportClick = useCallback(() => {
    openDrawer(
      <GenerateExportForm
        onComplete={handleExportComplete}
        filters={mergedFilters}
        defaultTitle={defaultExportTitle}
      />,
    )
  }, [defaultExportTitle, handleExportComplete, mergedFilters, openDrawer])

  const combinedTableOptions: Options<FlagModelType> = React.useMemo(
    () => ({
      filtering: true,
      toolbar: false,
      debounceInterval: 300,
      pageSize: 10,
      ...tableOptions,
    }),
    [tableOptions],
  )

  const columns = React.useMemo(
    (): Array<Column<FlagModelType>> => [
      {
        title: "Vet",
        field: "vet.name",
        filtering: false,
        hidden: Boolean(filters?.vetId),
      },
      {
        title: "Title",
        field: "title",
        filtering: true,
      },
      {
        title: "Text",
        field: "text",
        filtering: true,
      },
      {
        title: "Source",
        field: "source",
        filtering: true,
        lookup: FlagSourceLookup,
        filterComponent: SelectFilter,
      },
      {
        title: "Custom Source",
        field: "customSource",
        filtering: true,
      },
      {
        title: "Tags",
        field: "tags",
        render: (d) => d?.tags?.map((t: TagModelType) => t.name)?.join(", ") ?? "",
        filtering: true,
        lookup: tagLookup,
        filterComponent: MultiSelectFilter,
        sorting: false,
      },
      {
        title: "Clients",
        field: "clients",
        render: (d) => d?.clients?.join(", ") ?? "",
        filtering: true,
        lookup: clientLookup,
        filterComponent: SelectFilter,
        sorting: false,
      },
      {
        title: "Completed",
        field: "completedAt",
        render: (d) => formatDate(d?.completedAt),
        filtering: true,
        filterComponent: DateRangeFilter,
        defaultSort: "desc",
      },
      {
        title: "Image",
        field: "image",
        render: (d) => {
          return (
            <Box>
              {d.image ? (
                <Button
                  size="small"
                  onClick={() => {
                    openDialog({
                      title: "Flag Image",
                      body: (
                        <img src={d.image ?? ""} style={{ maxWidth: "500px" }} alt="Uploaded" />
                      ),
                      cancelText: "Close",
                    })
                  }}
                >
                  View
                </Button>
              ) : null}
            </Box>
          )
        },
        filtering: false,
        sorting: false,
      },
    ],
    [clientLookup, filters, openDialog, tagLookup],
  )

  const filteredColumns = useMemo(() => {
    if (!hideFields.length) return columns
    return columns.filter((c) => !hideFields.includes(c.field?.toString() ?? ""))
  }, [columns, hideFields])

  const handleFlagUpdated = useCallback(
    (flag: FlagModelType) => {
      closeDrawer()
      onRowUpdated && onRowUpdated(flag)
    },
    [closeDrawer, onRowUpdated],
  )

  const handleEditClick = useCallback(
    (flag: FlagModelType) => {
      openDrawer(
        <AddFlagForm
          onComplete={() => handleFlagUpdated(flag)}
          vetId={flag.vetId || ""}
          flag={flag}
        />,
      )
    },
    [handleFlagUpdated, openDrawer],
  )

  const handleDeleteFlag = useCallback(
    async (flag: FlagModelType) => {
      const response = (await store.mutateDeleteFlag({ id: flag.id }).currentPromise()) as {
        deleteFlag: MutationResponseModelType
      }

      if (hasMutationErrors(response)) {
        setToast(displayMutationError(response))
      } else {
        setToast("Deleted flag.")
        onRowUpdated && onRowUpdated(flag)
      }
    },
    [onRowUpdated, setToast, store],
  )

  const handleDeleteClick = useCallback(
    (flag: FlagModelType) => {
      openDialog({
        title: "Delete this record?",
        body: "This is permanent. Deleted records cannot be restored.",
        onConfirm: () => handleDeleteFlag(flag),
      })
    },
    [handleDeleteFlag, openDialog],
  )

  const rowActions: Array<Action<FlagModelType>> = useMemo(() => {
    const viewDetailsAction: Array<Action<FlagModelType>> = onRowClick
      ? [
          {
            icon: "pageview",
            tooltip: "View vet",
            onClick: (event, d): void => handleDetailsClick(rowDatum(d)),
          },
        ]
      : []

    const editAction: Array<Action<FlagModelType>> = showEdit
      ? [
          {
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, d): void => handleEditClick(rowDatum(d)),
          },
        ]
      : []

    const deleteAction: Array<Action<FlagModelType>> = showDelete
      ? [
          {
            icon: "delete",
            tooltip: "Delete",
            onClick: (event, d): void => handleDeleteClick(rowDatum(d)),
          },
        ]
      : []

    return [...viewDetailsAction, ...editAction, ...deleteAction]
  }, [handleDeleteClick, handleDetailsClick, handleEditClick, onRowClick, showDelete, showEdit])

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Flags</Typography>
      </AccordionSummary>
      <TableActions hidden={hideExport && !actions}>
        {!hideExport && (
          <Button variant="contained" onClick={handleExportClick}>
            Export
          </Button>
        )}
        {actions}
      </TableActions>
      <MaterialTable
        tableRef={tableRef}
        columns={filteredColumns}
        data={handleTableUpdate}
        options={combinedTableOptions}
        localization={{ header: { actions: "" } }}
        style={{ boxShadow: "none" }}
        actions={rowActions}
      />
    </Accordion>
  )
}

export default observer(FlagsTable)
