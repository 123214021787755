import { useEffect, useRef } from "react"

export function useTableCacheKey(cacheKey: any) {
  const tableRef = useRef()
  useEffect(() => {
    if (tableRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      tableRef.current?.onQueryChange()
    }
  }, [cacheKey])
  return tableRef
}
