import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import * as React from "react"

const useStyles = makeStyles(() => ({
  panelDetails: {
    display: "block",
  },
  disableContainerPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))

export interface ReadOnlyFormProps {
  children: JSX.Element | Array<JSX.Element>
  title: string | JSX.Element
  defaultExpanded?: boolean
  disableContainerPadding?: boolean
}

const ReadOnlyForm: React.FC<ReadOnlyFormProps> = ({
  title,
  children,
  defaultExpanded,
  disableContainerPadding,
}) => {
  const classes = useStyles()

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        className={`${classes.panelDetails} ${
          disableContainerPadding ? classes.disableContainerPadding : ""
        }`}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

ReadOnlyForm.defaultProps = {
  defaultExpanded: true,
}

export default ReadOnlyForm
