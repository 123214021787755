/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { BookStatusEnumType } from "./BookStatusEnum"
import { UserModel, UserModelType } from "./UserModel"
import { UserModelSelector } from "./UserModel.base"
import { VetModel, VetModelType } from "./VetModel"
import { VetModelSelector } from "./VetModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  user: UserModelType
  vet: VetModelType
}

/**
 * ExportBase
 * auto generated base class for the model ExportModel.
 */
export const ExportModelBase = withTypedRefs<Refs>()(
  ModelBase.named("Export")
    .props({
      __typename: types.optional(types.literal("Export"), "Export"),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      userId: types.union(types.undefined, types.string),
      user: types.union(types.undefined, MSTGQLRef(types.late((): any => UserModel))),
      title: types.union(types.undefined, types.string),
      status: types.union(types.undefined, BookStatusEnumType),
      filename: types.union(types.undefined, types.string),
      vetId: types.union(types.undefined, types.null, types.string),
      vet: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => VetModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class ExportModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get userId() {
    return this.__attr(`userId`)
  }
  get title() {
    return this.__attr(`title`)
  }
  get status() {
    return this.__attr(`status`)
  }
  get filename() {
    return this.__attr(`filename`)
  }
  get vetId() {
    return this.__attr(`vetId`)
  }
  user(
    builder?: string | UserModelSelector | ((selector: UserModelSelector) => UserModelSelector),
  ) {
    return this.__child(`user`, UserModelSelector, builder)
  }
  vet(builder?: string | VetModelSelector | ((selector: VetModelSelector) => VetModelSelector)) {
    return this.__child(`vet`, VetModelSelector, builder)
  }
}
export function selectFromExport() {
  return new ExportModelSelector()
}

export const exportModelPrimitives = selectFromExport().createdAt.updatedAt.userId.title.status
  .filename.vetId
