import React from "react"
import { RouteProps } from "react-router-dom"
import { POST_LOGIN_PATH } from "../../../constants/routes"
import PrivateRoute from "./PrivateRoute"

interface OnlyLoggedOutRouteProps extends RouteProps {
  redirect?: string
}

export const OnlyLoggedOutRoute: React.FC<OnlyLoggedOutRouteProps> = ({ redirect, ...rest }) => {
  return <PrivateRoute redirect={redirect || POST_LOGIN_PATH} mustBeLoggedOut={true} {...rest} />
}

export default OnlyLoggedOutRoute
