/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { VetModel, VetModelType } from "./VetModel"
import { VetModelSelector } from "./VetModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<VetModelType>
}

/**
 * VetListResponseBase
 * auto generated base class for the model VetListResponseModel.
 */
export const VetListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("VetListResponse")
    .props({
      __typename: types.optional(types.literal("VetListResponse"), "VetListResponse"),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => VetModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class VetListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?: string | VetModelSelector | ((selector: VetModelSelector) => VetModelSelector),
  ) {
    return this.__child(`records`, VetModelSelector, builder)
  }
}
export function selectFromVetListResponse() {
  return new VetListResponseModelSelector()
}

export const vetListResponseModelPrimitives = selectFromVetListResponse().count
