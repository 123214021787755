import {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from "@material-ui/core/TextField/TextField"
import { Field, FieldProps } from "formik"
import * as React from "react"
import { FormattedTextField } from "./"

export type FormTextFieldProps = { name: string } & (
  | StandardTextFieldProps
  | FilledTextFieldProps
  | OutlinedTextFieldProps
)

const FormTextField: React.FC<FormTextFieldProps> = ({ name, onChange, onBlur, ...props }) => (
  <Field name={name}>
    {({
      field: { value, onChange: formikOnChange, onBlur: formikOnBlur, ...field },
      meta,
    }: FieldProps): React.ReactElement => (
      <FormattedTextField
        error={!!meta.error && meta.touched}
        helperText={meta.touched && meta.error}
        disabled={false}
        value={value || ""}
        onChange={(event) => {
          formikOnChange(event)
          onChange && onChange(event)
        }}
        onBlur={(event) => {
          formikOnBlur(event)
          if (meta.initialValue !== meta.value) {
            onBlur && onBlur(event)
          }
        }}
        {...field}
        {...props}
      />
    )}
  </Field>
)

export default FormTextField
