import React from "react"
import { Theme } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    "& .MuiAlert-root": {
      marginBottom: theme.spacing(1),
    },
  },
}))

export type ErrorsProps = {
  errorMessages: Array<string>
}

const Errors: React.FC<ErrorsProps> = ({ errorMessages }) => {
  const classes = useStyles()

  if (!errorMessages.length) {
    return null
  }

  return (
    <div className={classes.container}>
      {errorMessages.map((error) => (
        <Alert key={error} severity="error">
          {error}
        </Alert>
      ))}
    </div>
  )
}

export default Errors
