import { FormControl, FormControlProps, TextField } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Field, FieldProps } from "formik"
import * as React from "react"
import { useMemo } from "react"

export interface SelectOption {
  value: string
  label: string
}

export type FormSelectFieldProps = {
  name: string
  label: string
  options: Array<SelectOption>
  disabled?: boolean
  FormControlProps?: FormControlProps
  multiple?: boolean
  clearable?: boolean
  onChange?: (selectOption?: SelectOption) => void
}

const FormSelectField: React.FC<FormSelectFieldProps> = ({
  name,
  label,
  options,
  disabled,
  multiple,
  FormControlProps,
  onChange,
  clearable = true,
}) => {
  const allOptions = useMemo(() => {
    const baseOptions: Array<SelectOption> = clearable ? [{ label: "", value: "" }] : []
    return [...baseOptions, ...options]
  }, [options, clearable])

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps): React.ReactElement => {
        const { setFieldTouched, setFieldValue } = form
        return (
          <FormControl
            size="small"
            disabled={disabled}
            error={!!meta.error && meta.touched}
            {...FormControlProps}
          >
            <Autocomplete
              {...field}
              value={field.value || null}
              options={allOptions.map((opt) => opt.value)}
              multiple={multiple}
              disableClearable={!clearable}
              disabled={disabled}
              onChange={(_, value): void => {
                if (onChange) {
                  const option = allOptions.find((opt) => opt.value === value)
                  onChange(option)
                }
                setFieldValue(field.name, value || undefined)
              }}
              onBlur={(): void => setFieldTouched(field.name)}
              getOptionLabel={(option): string =>
                allOptions.find((opt) => opt.value === option)?.label || ""
              }
              renderInput={(params): React.ReactElement => (
                <TextField
                  {...params}
                  variant="standard"
                  label={label}
                  helperText={meta.touched && meta.error}
                  error={Boolean(meta.touched && meta.error)}
                />
              )}
            />
          </FormControl>
        )
      }}
    </Field>
  )
}

FormSelectField.defaultProps = {
  FormControlProps: {},
  clearable: true,
}

export default FormSelectField
