import { Maybe } from "./types"
import { ToastContentProps } from "../components/common/utils/toast/Context"

type MutationResponse = {
  success?: boolean
  message?: string
}

export function getErrorMessage(mutationResponse: any): Maybe<string> {
  return Object.keys(mutationResponse)
    .map((key) => {
      const value: MutationResponse = mutationResponse[key]
      if (value.success !== undefined && !value.success) {
        return value?.message
      }
      return undefined
    })
    .find(Boolean)
}

export function hasMutationErrors(mutationResponse: any): boolean {
  return Boolean(getErrorMessage(mutationResponse))
}

export function displayMutationError(
  mutationResponse: any,
  actionDescription?: string,
): ToastContentProps {
  const errorAction = actionDescription ? ` while ${actionDescription}` : ""
  return {
    message: `Error${errorAction}: ${getErrorMessage(mutationResponse)}`,
    variant: "error",
  }
}
