import {
  Box,
  IconButton,
  InputAdornment,
  makeStyles,
  Popper,
  Tab,
  Tabs,
  TextField,
  Theme,
} from "@material-ui/core"
import ClearIcon from "@material-ui/icons/Clear"
import { TabContext, TabPanel } from "@material-ui/lab"
import { StaticDatePicker } from "@material-ui/pickers"
import { DateTime } from "luxon"
import * as React from "react"
import { useCallback } from "react"
import { formatDateRange, formatDateTime, IDateRange } from "../../../utilities/dates"

const useStyles = makeStyles((theme: Theme) => ({
  popperContainer: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    zIndex: 9999,
  },
  panel: {
    padding: 0,
  },
}))

export type DateRangePickerProps = {
  onChange: (dateRange: IDateRange) => void
  value: IDateRange
  minYear?: number
  maxYear?: number
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  onChange,
  minYear = 1980,
  maxYear = DateTime.utc().year + 1,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popper" : undefined

  const [tabIndex, setTabIndex] = React.useState("0")

  const handleTabChange = React.useCallback(
    (event: React.ChangeEvent<{}>, newValue: string) => {
      setTabIndex(newValue)
    },
    [setTabIndex],
  )
  const handleMouseDownClear = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }, [])
  const displayValue = value?.start || value?.end ? formatDateRange(value) : ""

  return (
    <>
      <Box display="flex">
        <TextField
          variant="standard"
          fullWidth
          value={displayValue}
          onClick={handleClick}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  disabled={!displayValue}
                  onClick={() => {
                    onChange({})
                    setTabIndex("0")
                  }}
                  onMouseDown={handleMouseDownClear}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        position="fixed"
        hidden={!open}
        onClick={() => setAnchorEl(null)}
        top={0}
        left={0}
        right={0}
        bottom={0}
      />
      <Popper id={id} open={open} anchorEl={anchorEl} className={classes.popperContainer}>
        <TabContext value={tabIndex}>
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="simple tabs example">
            <Tab label={formatDateTime(value?.start, "From")} value="0" />
            <Tab label={formatDateTime(value?.end, "To")} value="1" />
          </Tabs>
          <TabPanel value="0" className={classes.panel}>
            <StaticDatePicker
              value={value?.start}
              onChange={(date: DateTime | null): void => {
                onChange({
                  ...value,
                  start: date || undefined,
                })
                setTabIndex("1")
              }}
              renderInput={(): React.ReactElement => <div />}
              showToolbar={false}
              disableHighlightToday
              minDate={DateTime.fromObject({ year: minYear }).toISO()}
              maxDate={DateTime.fromObject({ year: maxYear }).toISO()}
              clearable
            />
          </TabPanel>
          <TabPanel value="1" className={classes.panel}>
            <StaticDatePicker
              value={value?.end}
              onChange={(date: DateTime | null): void => {
                onChange({
                  ...value,
                  end: date || undefined,
                })
                setAnchorEl(null)
              }}
              renderInput={(): React.ReactElement => <div />}
              showToolbar={false}
              disableHighlightToday
              minDate={DateTime.fromObject({ year: minYear }).toISO()}
              maxDate={DateTime.fromObject({ year: maxYear }).toISO()}
              clearable
            />
          </TabPanel>
        </TabContext>
      </Popper>
    </>
  )
}

export default DateRangePicker
