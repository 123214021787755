/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { VetModel, VetModelType } from "./VetModel"
import { VetModelSelector } from "./VetModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  vet: VetModelType
}

/**
 * VetUpdateResponseBase
 * auto generated base class for the model VetUpdateResponseModel.
 */
export const VetUpdateResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("VetUpdateResponse")
    .props({
      __typename: types.optional(types.literal("VetUpdateResponse"), "VetUpdateResponse"),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      vet: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => VetModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class VetUpdateResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  vet(builder?: string | VetModelSelector | ((selector: VetModelSelector) => VetModelSelector)) {
    return this.__child(`vet`, VetModelSelector, builder)
  }
}
export function selectFromVetUpdateResponse() {
  return new VetUpdateResponseModelSelector()
}

export const vetUpdateResponseModelPrimitives = selectFromVetUpdateResponse().success.message
