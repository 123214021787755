/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { VetModel, VetModelType } from "./VetModel"
import { VetModelSelector } from "./VetModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  vet: VetModelType
}

/**
 * EmailBase
 * auto generated base class for the model EmailModel.
 */
export const EmailModelBase = withTypedRefs<Refs>()(
  ModelBase.named("Email")
    .props({
      __typename: types.optional(types.literal("Email"), "Email"),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      email: types.union(types.undefined, types.string),
      vetId: types.union(types.undefined, types.string),
      vet: types.union(types.undefined, MSTGQLRef(types.late((): any => VetModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class EmailModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get email() {
    return this.__attr(`email`)
  }
  get vetId() {
    return this.__attr(`vetId`)
  }
  vet(builder?: string | VetModelSelector | ((selector: VetModelSelector) => VetModelSelector)) {
    return this.__child(`vet`, VetModelSelector, builder)
  }
}
export function selectFromEmail() {
  return new EmailModelSelector()
}

export const emailModelPrimitives = selectFromEmail().createdAt.updatedAt.email.vetId
