import { Accordion, AccordionSummary, Box, Typography } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { observer } from "mobx-react"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import VetsTable from "src/components/vets/VetsTable"
import { ActionButtonContainer, Button, Page, PageHeader } from "../../components"
import VetFiltersForm from "../../components/vets/VetFiltersForm"
import { Paths } from "../../constants/routes"
import { VetFilters } from "../../models/RootStore.base"

const VetsListPage: React.FC = () => {
  const history = useHistory()
  const [filters, setFilters] = useState<VetFilters>({})

  return (
    <Page
      header={
        <PageHeader title="Vets" breadcrumbs={[{ label: "Vets" }]}>
          <ActionButtonContainer>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(Paths.VetCreate)}
            >
              Create
            </Button>
          </ActionButtonContainer>
        </PageHeader>
      }
    >
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Search</Typography>
        </AccordionSummary>
        <Box padding={2} paddingTop={0}>
          <VetFiltersForm onChange={setFilters} />
        </Box>
      </Accordion>
      <VetsTable filters={filters} cacheKey={filters} />
    </Page>
  )
}

export default observer(VetsListPage)
