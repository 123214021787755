/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum UserRole {
  USER = "USER",
  ADMIN = "ADMIN",
  SUPERADMIN = "SUPERADMIN",
}

/**
 * UserRole
 */
export const UserRoleEnumType = types.enumeration("UserRole", ["USER", "ADMIN", "SUPERADMIN"])
