import { Button } from "@material-ui/core"
import { Formik, FormikProps } from "formik"
import * as React from "react"
import * as Yup from "yup"
import { useStore } from "../../getMstGql"
import { AddressModelType, State } from "../../models"
import { displayMutationError, hasMutationErrors } from "../../utilities/errors"
import { formatState } from "../../utilities/states"
import { FormSelectField, useToast } from "../common"
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormTextField,
} from "../common/forms"

function getInitialValues(record?: AddressModelType) {
  return {
    state: record?.state ?? "",
    streetAddress: record?.streetAddress ?? "",
    city: record?.city ?? "",
  }
}

const schema = Yup.object().shape({
  state: Yup.string().required("State is required"),
  streetAddress: Yup.string().required("Street is required"),
  city: Yup.string().required("City is required"),
})

export interface AddressFormProps {
  onComplete: (updated?: boolean) => any
  vetId: string
  record?: AddressModelType
}

const AddressForm: React.FC<AddressFormProps> = ({ onComplete, vetId, record }) => {
  const store = useStore()
  const { setToast } = useToast()

  return (
    <FormContainer title={record ? "Edit Address" : "Add Address"}>
      <Formik
        initialValues={getInitialValues(record)}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          try {
            actions.setSubmitting(true)

            const response = record
              ? await store
                  .mutateUpdateAddress({
                    id: record.id,
                    input: {
                      state: values.state as State,
                      city: values.city,
                      streetAddress: values.streetAddress,
                      vetId,
                    },
                  })
                  .currentPromise()
              : await store
                  .mutateCreateAddress({
                    input: {
                      state: values.state as State,
                      city: values.city,
                      streetAddress: values.streetAddress,
                      vetId,
                    },
                  })
                  .currentPromise()

            if (hasMutationErrors(response)) {
              setToast(displayMutationError(response))
              return
            }

            setToast({
              message: `Address has been ${record ? "updated" : "added"}!`,
              variant: "success",
            })
            actions.resetForm()
            onComplete(true)
          } finally {
            actions.setSubmitting(false)
          }
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="">
              <FieldGroup>
                <FormTextField label="Street" name="streetAddress" multiline />
              </FieldGroup>

              <FieldGroup>
                <FormTextField label="City" name="city" />
                <FormSelectField
                  label="State"
                  name="state"
                  options={Object.values(State).map((value) => ({
                    value: value,
                    label: formatState(value),
                  }))}
                  clearable={false}
                />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                onClick={() => {
                  return props.submitForm()
                }}
              >
                Save
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default AddressForm
