/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { EmailModel, EmailModelType } from "./EmailModel"
import { EmailModelSelector } from "./EmailModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  email: EmailModelType
}

/**
 * EmailCreationResponseBase
 * auto generated base class for the model EmailCreationResponseModel.
 */
export const EmailCreationResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("EmailCreationResponse")
    .props({
      __typename: types.optional(types.literal("EmailCreationResponse"), "EmailCreationResponse"),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      email: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => EmailModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class EmailCreationResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  email(
    builder?: string | EmailModelSelector | ((selector: EmailModelSelector) => EmailModelSelector),
  ) {
    return this.__child(`email`, EmailModelSelector, builder)
  }
}
export function selectFromEmailCreationResponse() {
  return new EmailCreationResponseModelSelector()
}

export const emailCreationResponseModelPrimitives = selectFromEmailCreationResponse().success
  .message
