/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { VetModel, VetModelType } from "./VetModel"
import { VetModelSelector } from "./VetModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  vet: VetModelType
}

/**
 * VetCreationResponseBase
 * auto generated base class for the model VetCreationResponseModel.
 */
export const VetCreationResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("VetCreationResponse")
    .props({
      __typename: types.optional(types.literal("VetCreationResponse"), "VetCreationResponse"),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      vet: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => VetModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class VetCreationResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  vet(builder?: string | VetModelSelector | ((selector: VetModelSelector) => VetModelSelector)) {
    return this.__child(`vet`, VetModelSelector, builder)
  }
}
export function selectFromVetCreationResponse() {
  return new VetCreationResponseModelSelector()
}

export const vetCreationResponseModelPrimitives = selectFromVetCreationResponse().success.message
