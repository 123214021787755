/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum EntityType {
  Person = "Person",
  Organization = "Organization",
}

/**
 * EntityType
 */
export const EntityTypeEnumType = types.enumeration("EntityType", ["Person", "Organization"])
