import { Field, FieldProps } from "formik"
import * as React from "react"
import DateInput, { DateInputProps } from "./DateInput"

export type FormTextFieldProps = { name: string } & Omit<DateInputProps, "value" | "onChange">

const FormTextField: React.FC<FormTextFieldProps> = (props) => (
  <Field name={props.name}>
    {({
      field: { value, onChange, ...field },
      meta,
      form: { setFieldValue },
    }: FieldProps): React.ReactElement => {
      return (
        <DateInput
          textFieldProps={{
            error: !!meta.error && meta.touched,
            helperText: meta.touched && meta.error,
            ...field,
          }}
          value={value || null}
          onChange={(date): void => {
            setFieldValue(props.name, date || undefined)
          }}
          {...props}
        />
      )
    }}
  </Field>
)

export default FormTextField
