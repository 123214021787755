import * as React from "react"
import { useHistory } from "react-router-dom"
import { FlagsTable, Page, PageHeader } from "src/components"
import { FlagModelType } from "../../models"

const FlagsListPage: React.FC = () => {
  const history = useHistory()
  const handleRowClick = React.useCallback(
    (row: FlagModelType): void => {
      history.push(`/vets/${row.vetId}`)
    },
    [history],
  )

  return (
    <Page header={<PageHeader title="Flags" breadcrumbs={[{ label: "Flags" }]} />}>
      <FlagsTable onRowClick={handleRowClick} />
    </Page>
  )
}

export default FlagsListPage
