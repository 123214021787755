import { AppBar, Button, Tab, Tabs, Tooltip } from "@material-ui/core"
import { TabContext, TabPanel } from "@material-ui/lab"
import { Formik, FormikProps } from "formik"
import debounce from "lodash/debounce"
import { observer } from "mobx-react-lite"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import {
  ActionButtonContainer,
  AddFlagForm,
  FieldGroup,
  FlagsTable,
  FormSelectField,
  FormTextField,
  LoadingContainer,
  Page,
  PageHeader,
  ReadOnlyForm,
  RequiresRole,
  useModal,
  useToast,
} from "src/components"
import * as Yup from "yup"
import { ObjectSchema } from "yup"
import AddressTable from "../../components/addresses/AddressTable"
import AliasTable from "../../components/aliases/AliasTable"
import { BreadcrumbItem } from "../../components/common/page/PageHeader"
import LoadingOverlay from "../../components/common/utils/LoadingOverlay"
import EmailTable from "../../components/emails/EmailTable"
import ExportsTable from "../../components/exports/ExportsTable"
import GenerateExportForm from "../../components/exports/GenerateExportForm"
import PhoneNumberTable from "../../components/phoneNumbers/PhoneNumberTable"
import { Paths } from "../../constants/routes"
import { useStore } from "../../getMstGql"
import {
  ClientListResponseModelType,
  ClientModelType,
  clientsSelector,
  EntityType,
  MutationResponseModelType,
  selectFromVetCreationResponse,
  UserRole,
  VetCreationResponseModelType,
  VetModelType,
  VetUpdateResponseModelType,
} from "../../models"
import { useQuery } from "../../models/reactUtils"
import { CreateVetInput, UpdateVetInput } from "../../models/RootStore.base"
import { vetSelector } from "../../models/selectors"
import { displayMutationError, hasMutationErrors } from "../../utilities/errors"
import { UserDetailsPageParams } from "../users/details"

type DetailsQuery = { vet: VetModelType }

function getPageTitle(vet?: VetModelType): string {
  return vet ? vet.name ?? "" : "Create Vet"
}

function buildVetsBreadCrumbs(vet?: VetModelType): Array<BreadcrumbItem> {
  const vetItem: Array<BreadcrumbItem> = vet ? [{ label: vet.name ?? "" }] : []
  return [{ label: "Vets", link: Paths.VetsList }, ...vetItem]
}

export interface VetDetailsPageParams {
  id?: string
}

type VetInput = {
  entityType: EntityType
  firstName: string | null
  lastName: string | null | undefined
  middleName: string | null | undefined
  title: string | null | undefined
  clientIds: Array<string> | null | undefined
}

function getInitialFormValues(vet?: VetModelType): VetInput {
  return {
    entityType: (vet?.entityType as EntityType) ?? EntityType.Person,
    firstName: vet?.firstName ?? null,
    lastName: vet?.lastName ?? null,
    middleName: vet?.middleName ?? null,
    title: vet?.title ?? null,
    clientIds: Array.from(vet?.clientIds ?? []),
  }
}

const VALIDATION_SCHEMA: ObjectSchema<VetInput> = Yup.object()
  .required()
  .shape({
    entityType: Yup.string().required("Required").oneOf(Object.values(EntityType)),
    firstName: Yup.string().required("Required").typeError("Required"),
    lastName: Yup.string().when("entityType", {
      is: EntityType.Person,
      then: Yup.string().required("Required").typeError("Required"),
      otherwise: Yup.string().nullable(),
    }),
    middleName: Yup.string().nullable(),
    title: Yup.string().nullable(),
    clientIds: Yup.array(Yup.string().required("Required")).nullable(),
  })

const VetDetailsPage: React.FC = () => {
  const { id } = useParams<UserDetailsPageParams>()
  const [tabNumber, setTabNumber] = useState("0")
  const [cacheKey, setCacheKey] = useState(Date.now())
  const updateCacheKey = useCallback(() => setCacheKey(Date.now()), [setCacheKey])
  const { openDrawer, closeDrawer, openDialog } = useModal()
  const history = useHistory()
  const { mutateCreateVet, mutateUpdateVet, mutateDeleteVet } = useStore()
  const { setToast } = useToast()
  const { data, setQuery, query } = useQuery<DetailsQuery>()
  const [vet, setVet] = useState<VetModelType | undefined>()

  const { data: clientsData } = useQuery<{ clients: ClientListResponseModelType }>((store) =>
    store.queryClients({}, clientsSelector),
  )

  const clients: Array<ClientModelType> = useMemo(
    () => clientsData?.clients?.records?.toJS() ?? [],
    [clientsData],
  )

  useEffect(() => {
    if (id && id !== "new") {
      setQuery((store) => store.queryVet({ id }, vetSelector))
    }
  }, [id, setQuery, vet])
  useEffect(() => {
    if (!vet && data?.vet) {
      setVet(data.vet)
    }
  }, [data, vet])

  const handleFlagAdded = useCallback(() => {
    closeDrawer()
    updateCacheKey()
  }, [closeDrawer, updateCacheKey])

  const handleAddFlag = useCallback(() => {
    if (!vet) return

    openDrawer(<AddFlagForm vetId={vet.id} onComplete={handleFlagAdded} />)
  }, [handleFlagAdded, openDrawer, vet])

  const handleDelete = useCallback(async () => {
    if (!vet) return
    const response = (await mutateDeleteVet({ id: vet.id }).currentPromise()) as {
      deleteVet: MutationResponseModelType
    }

    if (hasMutationErrors(response)) {
      setToast(displayMutationError(response))
    } else {
      setToast("Deleted vet.")
      history.push(Paths.VetsList)
    }
  }, [history, mutateDeleteVet, setToast, vet])

  const formikPropsRef = useRef<FormikProps<any>>()

  const handleFormChange = useCallback(() => {
    const props = formikPropsRef.current
    if (props) {
      const { submitForm, isValid, isSubmitting } = props
      if (isValid && !isSubmitting) {
        submitForm()
      }
    }
  }, [])

  const handleFormChangeDebounced = useMemo(() => debounce(handleFormChange, 1500), [
    handleFormChange,
  ])

  const touchAndValidateForm = useCallback(() => {
    const props = formikPropsRef.current
    if (props) {
      const { setTouched, validateForm } = props
      setTouched({ firstName: true, lastName: true })
      validateForm()
    }
  }, [])

  const handleExportComplete = useCallback(() => {
    closeDrawer()
  }, [closeDrawer])

  const handleExportClick = useCallback(() => {
    if (!vet) return
    openDrawer(
      <GenerateExportForm
        onComplete={handleExportComplete}
        filters={{ vetId: vet.id }}
        defaultTitle={`${vet.name} Export`}
        redirectOnCreate={false}
      />,
    )
  }, [handleExportComplete, openDrawer, vet])

  const refreshVet = useCallback(() => {
    query?.refetch()
  }, [query])

  const loading = Boolean(!vet && id && id !== "new")
  return (
    <LoadingContainer loading={loading}>
      <Page
        header={
          <PageHeader title={getPageTitle(vet)} breadcrumbs={buildVetsBreadCrumbs(vet)}>
            <ActionButtonContainer>
              {vet ? (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 7 }}
                  onClick={handleExportClick}
                >
                  Export
                </Button>
              ) : null}
              <RequiresRole role={UserRole.ADMIN}>
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ marginRight: 7 }}
                  onClick={() => {
                    openDialog({
                      title: "Delete this record?",
                      body: "This is permanent. Deleted records cannot be restored.",
                      onConfirm: handleDelete,
                    })
                  }}
                >
                  Delete
                </Button>
              </RequiresRole>
            </ActionButtonContainer>
          </PageHeader>
        }
      >
        <Formik
          validationSchema={VALIDATION_SCHEMA}
          initialValues={getInitialFormValues(vet)}
          onSubmit={async (values: VetInput, helpers): Promise<void> => {
            helpers.setSubmitting(true)
            try {
              if (vet) {
                // Update existing vet
                const input: UpdateVetInput = {
                  title: values.title || undefined,
                  entityType: values.entityType,
                  firstName: values.firstName || "",
                  lastName: values.lastName || undefined,
                  middleName: values.middleName || undefined,
                  clientIds: values.clientIds || undefined,
                }
                const response: { updateVet: VetUpdateResponseModelType } = await mutateUpdateVet(
                  { id: vet.id, input },
                  selectFromVetCreationResponse().message.success.vet(vetSelector).toString(),
                )
                if (response.updateVet.success) {
                  setVet(response.updateVet.vet)
                  setToast({ message: "Vet updated!", variant: "success" })
                } else {
                  setToast({
                    message: `Error udpating vet: ${response.updateVet.message}`,
                    variant: "error",
                  })
                }
              } else {
                // Create new vet
                const input: CreateVetInput = {
                  title: values.title || undefined,
                  entityType: values.entityType,
                  firstName: values.firstName || "",
                  lastName: values.lastName || undefined,
                  middleName: values.middleName || undefined,
                  clientIds: values.clientIds || undefined,
                }
                const response: {
                  createVet: VetCreationResponseModelType
                } = await mutateCreateVet(
                  { input },
                  selectFromVetCreationResponse().message.success.vet(vetSelector).toString(),
                )
                if (response.createVet.success) {
                  setVet(response.createVet.vet)
                  history.replace(`/vets/${response.createVet.vet.id}`)
                  setToast({ message: "Vet created!", variant: "success" })
                } else {
                  setToast({
                    message: `Error creating vet: ${response.createVet.message}`,
                    variant: "error",
                  })
                }
              }
            } catch (e) {
              setToast({ message: `Error creating vet: ${e.message}`, variant: "error" })
            } finally {
              helpers.setSubmitting(false)
            }
          }}
          validateOnMount={Boolean(id)}
        >
          {(props: FormikProps<any>) => {
            const { values } = props
            formikPropsRef.current = props
            const orgMode = values["entityType"] === EntityType.Organization

            return (
              <>
                <ReadOnlyForm title="Subject" disableContainerPadding>
                  <LoadingOverlay loading={!vet && props.isSubmitting} />
                  <AppBar position="static">
                    <Tabs
                      value={parseInt(tabNumber)}
                      onChange={(evt, value) => {
                        setTabNumber(value.toString())
                      }}
                    >
                      <Tab label="General" />
                      <Tab label="Addresses" disabled={!vet} />
                      <Tab label="Phone numbers" disabled={!vet} />
                      <Tab label="Emails" disabled={!vet} />
                      <Tab label="Aliases" disabled={!vet} />
                    </Tabs>
                  </AppBar>
                  <TabContext value={tabNumber}>
                    <TabPanel value="0">
                      <FieldGroup>
                        <FormSelectField
                          disabled={Boolean(vet)}
                          label="Type"
                          name="entityType"
                          options={Object.values(EntityType).map((value) => ({
                            value,
                            label: value,
                          }))}
                          clearable={false}
                        />
                      </FieldGroup>
                      <FieldGroup>
                        {!orgMode ? (
                          <FormTextField
                            onChange={handleFormChangeDebounced}
                            onBlur={handleFormChangeDebounced}
                            label="Title (optional)"
                            name="title"
                          />
                        ) : (
                          <React.Fragment />
                        )}
                        <FormTextField
                          onChange={handleFormChangeDebounced}
                          onBlur={handleFormChangeDebounced}
                          label={orgMode ? "Name" : "First name"}
                          name="firstName"
                        />
                        {!orgMode ? (
                          <FormTextField
                            onChange={handleFormChangeDebounced}
                            onBlur={handleFormChangeDebounced}
                            label="Middle name (optional)"
                            name="middleName"
                          />
                        ) : (
                          <React.Fragment />
                        )}
                        {!orgMode ? (
                          <FormTextField
                            onChange={handleFormChangeDebounced}
                            onBlur={handleFormChangeDebounced}
                            label="Last name"
                            name="lastName"
                          />
                        ) : (
                          <React.Fragment />
                        )}
                      </FieldGroup>
                      <FieldGroup>
                        <FormSelectField
                          disabled={!vet}
                          label="Clients"
                          name="clientIds"
                          options={clients.map((value) => ({
                            value: value.id,
                            label: value.name ?? "",
                          }))}
                          multiple
                          clearable={false}
                          onChange={handleFormChangeDebounced}
                        />
                      </FieldGroup>
                    </TabPanel>
                    <TabPanel value="1" style={{ padding: 0 }}>
                      <AddressTable
                        addresses={Array.from(vet?.addresses?.toJS() ?? [])}
                        vetId={vet?.id ?? ""}
                        onUpdate={refreshVet}
                      />
                    </TabPanel>
                    <TabPanel value="2" style={{ padding: 0 }}>
                      <PhoneNumberTable
                        records={Array.from(vet?.phoneNumbers?.toJS() ?? [])}
                        vetId={vet?.id ?? ""}
                        onUpdate={refreshVet}
                      />
                    </TabPanel>
                    <TabPanel value="3" style={{ padding: 0 }}>
                      <EmailTable
                        records={Array.from(vet?.emails?.toJS() ?? [])}
                        vetId={vet?.id ?? ""}
                        onUpdate={refreshVet}
                      />
                    </TabPanel>
                    <TabPanel value="4" style={{ padding: 0 }}>
                      <AliasTable
                        records={Array.from(vet?.aliases?.toJS() ?? [])}
                        vetId={vet?.id ?? ""}
                        onUpdate={refreshVet}
                        entityType={(vet?.entityType as EntityType) ?? EntityType.Person}
                      />
                    </TabPanel>
                  </TabContext>
                </ReadOnlyForm>

                <FlagsTable
                  cacheKey={cacheKey}
                  filters={{ vetId: vet?.id ?? "new" }}
                  hideExport
                  showEdit
                  showDelete
                  onRowUpdated={updateCacheKey}
                  actions={
                    <Tooltip title={vet ? "Add flag" : "Complete subject info to add flags"}>
                      <Button
                        variant="contained"
                        onClick={vet ? handleAddFlag : touchAndValidateForm}
                      >
                        Add
                      </Button>
                    </Tooltip>
                  }
                />

                {vet && <ExportsTable filters={{ vetId: vet.id }} />}
              </>
            )
          }}
        </Formik>
      </Page>
    </LoadingContainer>
  )
}

export default observer(VetDetailsPage)
