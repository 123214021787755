import { Button } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Page } from "../../components"
import {
  ActionButtonContainer,
  FieldGroup,
  PageHeader,
  ReadOnlyForm,
  ReadOnlyTextField,
} from "../../components/common"
import { LoadingContainer, NotFound } from "../../components/common/utils"
import { useModal } from "../../components/common/utils/modal"
import { useToast } from "../../components/common/utils/toast"
import EditUserForm from "../../components/users/EditUserForm"
import { Paths } from "../../constants/routes"
import { useStore } from "../../getMstGql"
import { MutationResponseModelType, UserModelType } from "../../models"
import { useQuery } from "../../models/reactUtils"
import { canModifyUser } from "../../utilities/authorization"
import { displayMutationError, hasMutationErrors } from "../../utilities/errors"

export interface UserDetailsPageParams {
  id: string
}

const UserDetailsPage = () => {
  const { id } = useParams<UserDetailsPageParams>()
  const { loading, data, query } = useQuery<{ user: UserModelType }>((store) =>
    store.queryUser({ id }),
  )
  const {
    mutateDeleteUser,
    authentication: { currentUser },
  } = useStore()
  const history = useHistory()
  const { setToast } = useToast()
  const { openDialog, openDrawer, closeDrawer } = useModal()

  const user = useMemo(() => {
    return data?.user
  }, [data])

  const handleDeleteUser = useCallback(async () => {
    const response = (await mutateDeleteUser({ id }).currentPromise()) as {
      deleteUser: MutationResponseModelType
    }

    if (hasMutationErrors(response)) {
      setToast(displayMutationError(response))
    } else {
      setToast("Deleted user.")
      history.push(Paths.UsersList)
    }
  }, [id, mutateDeleteUser, setToast, history])

  const handleUpdateUser = useCallback(() => {
    closeDrawer()
    query?.refetch()
  }, [query, closeDrawer])

  if (loading && !user) {
    return <LoadingContainer loading={loading} />
  }

  if (!user || !currentUser) {
    return <NotFound />
  }

  return (
    <LoadingContainer loading={loading && !user}>
      <Page
        header={
          <PageHeader
            title={user.name || ""}
            breadcrumbs={[{ link: Paths.UsersList, label: "Users" }, { label: user.name || "" }]}
          >
            <ActionButtonContainer>
              <Button
                variant="outlined"
                color="secondary"
                style={{ marginRight: 7 }}
                onClick={() => {
                  openDialog({
                    title: "Delete this record?",
                    body: "This is permanent. Deleted records cannot be restored.",
                    onConfirm: handleDeleteUser,
                  })
                }}
                hidden={!canModifyUser(currentUser, user)}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  openDrawer(<EditUserForm user={user} onComplete={handleUpdateUser} />)
                }
                hidden={!canModifyUser(currentUser, user)}
              >
                Edit
              </Button>
            </ActionButtonContainer>
          </PageHeader>
        }
      >
        <ReadOnlyForm title="General">
          <FieldGroup>
            <ReadOnlyTextField label="Email" value={user?.email} />
          </FieldGroup>
          <FieldGroup>
            <ReadOnlyTextField label="Name" value={user?.name} />
          </FieldGroup>
          <FieldGroup>
            <ReadOnlyTextField label="Role" value={user?.role} />
          </FieldGroup>
        </ReadOnlyForm>
      </Page>
    </LoadingContainer>
  )
}

export default observer(UserDetailsPage)
