/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AddressModel, AddressModelType } from "./AddressModel"
import { AddressModelSelector } from "./AddressModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  address: AddressModelType
}

/**
 * AddressCreationResponseBase
 * auto generated base class for the model AddressCreationResponseModel.
 */
export const AddressCreationResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("AddressCreationResponse")
    .props({
      __typename: types.optional(
        types.literal("AddressCreationResponse"),
        "AddressCreationResponse",
      ),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      address: types.union(
        types.undefined,
        types.null,
        MSTGQLRef(types.late((): any => AddressModel)),
      ),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class AddressCreationResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  address(
    builder?:
      | string
      | AddressModelSelector
      | ((selector: AddressModelSelector) => AddressModelSelector),
  ) {
    return this.__child(`address`, AddressModelSelector, builder)
  }
}
export function selectFromAddressCreationResponse() {
  return new AddressCreationResponseModelSelector()
}

export const addressCreationResponseModelPrimitives = selectFromAddressCreationResponse().success
  .message
