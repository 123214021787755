/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FlagSourceEnumType } from "./FlagSourceEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { VetModel, VetModelType } from "./VetModel"
import { VetModelSelector } from "./VetModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  vet: VetModelType
  tags: IObservableArray<TagModelType>
}

/**
 * FlagBase
 * auto generated base class for the model FlagModel.
 */
export const FlagModelBase = withTypedRefs<Refs>()(
  ModelBase.named("Flag")
    .props({
      __typename: types.optional(types.literal("Flag"), "Flag"),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      url: types.union(types.undefined, types.null, types.string),
      customSource: types.union(types.undefined, types.null, types.string),
      title: types.union(types.undefined, types.string),
      text: types.union(types.undefined, types.string),
      image: types.union(types.undefined, types.null, types.string),
      completedAt: types.union(types.undefined, types.frozen()),
      source: types.union(types.undefined, FlagSourceEnumType),
      vetId: types.union(types.undefined, types.string),
      vet: types.union(types.undefined, MSTGQLRef(types.late((): any => VetModel))),
      tags: types.union(types.undefined, types.array(MSTGQLRef(types.late((): any => TagModel)))),
      clients: types.union(types.undefined, types.array(types.string)),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class FlagModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get url() {
    return this.__attr(`url`)
  }
  get customSource() {
    return this.__attr(`customSource`)
  }
  get title() {
    return this.__attr(`title`)
  }
  get text() {
    return this.__attr(`text`)
  }
  get image() {
    return this.__attr(`image`)
  }
  get completedAt() {
    return this.__attr(`completedAt`)
  }
  get source() {
    return this.__attr(`source`)
  }
  get vetId() {
    return this.__attr(`vetId`)
  }
  get clients() {
    return this.__attr(`clients`)
  }
  vet(builder?: string | VetModelSelector | ((selector: VetModelSelector) => VetModelSelector)) {
    return this.__child(`vet`, VetModelSelector, builder)
  }
  tags(builder?: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector)) {
    return this.__child(`tags`, TagModelSelector, builder)
  }
}
export function selectFromFlag() {
  return new FlagModelSelector()
}

export const flagModelPrimitives = selectFromFlag().createdAt.updatedAt.url.customSource.title.text
  .image.completedAt.source.vetId.clients
