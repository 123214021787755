/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"

/**
 * ClientBase
 * auto generated base class for the model ClientModel.
 */
export const ClientModelBase = ModelBase.named("Client")
  .props({
    __typename: types.optional(types.literal("Client"), "Client"),
    id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    name: types.union(types.undefined, types.string),
  })
  .views((self) => ({
    get store() {
      return self.__getStore<RootStoreType>()
    },
  }))

export class ClientModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get name() {
    return this.__attr(`name`)
  }
}
export function selectFromClient() {
  return new ClientModelSelector()
}

export const clientModelPrimitives = selectFromClient().createdAt.updatedAt.name
