import { IconButton, Menu, MenuItem } from "@material-ui/core"
import AccountCircle from "@material-ui/icons/AccountCircle"
import * as React from "react"
import { useHistory } from "react-router-dom"
import { Paths } from "src/constants/routes"
import { useStore } from "../../../getMstGql"

const AppBarMenu: React.FC = () => {
  const store = useStore()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void =>
    setAnchorEl(event.currentTarget)
  const handleClose = (): void => setAnchorEl(null)

  return (
    <React.Fragment>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={(): void => {
            history.push(Paths.UserProfile)
            handleClose()
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={(): void => {
            store.logout()
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default AppBarMenu
