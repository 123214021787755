import {
  Accordion,
  AccordionSummary,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import DownloadIcon from "@material-ui/icons/GetApp"
import MaterialTable, { Column } from "material-table"
import { observer } from "mobx-react-lite"
import * as React from "react"
import { useMemo } from "react"
import { filterAndSearchDate, formatDate } from "src/utilities/dates"
import { API_SERVER_URL } from "../../constants/api"
import {
  ExportListResponseModelSelector,
  ExportListResponseModelType,
  ExportModelType,
  userModelPrimitives,
} from "../../models"
import { useQuery, useQueryPoll } from "../../models/reactUtils"
import { ExportFilters } from "../../models/RootStore.base"

const renderDownloadLink = (exportFile: ExportModelType) => {
  return (
    <Tooltip title="Download export">
      <Link href={`${API_SERVER_URL}/exports/download/${exportFile.id}`} target="_blank">
        <IconButton>
          <DownloadIcon />
        </IconButton>
      </Link>
    </Tooltip>
  )
}

const selector = new ExportListResponseModelSelector()
  .records((sel) => sel.createdAt.title.status.user(userModelPrimitives))
  .toString()

export type BooksTableProps = {
  filters?: ExportFilters
  cacheKey?: any
}

const BooksTable: React.FC<BooksTableProps> = ({ filters }) => {
  const { loading, data, query } = useQuery<{ exports: ExportListResponseModelType }>((store) =>
    store.queryExports({ filters }, selector),
  )
  useQueryPoll(query)

  const columns = React.useMemo(
    (): Array<Column<ExportModelType>> => [
      { title: "Name", field: "title" },
      {
        title: "Created at",
        field: "createdAt",
        defaultSort: "desc",
        render: (d) => formatDate(d.createdAt),
        customFilterAndSearch: (term: string, d) => filterAndSearchDate(d.createdAt, term),
      },
      { title: "Status", field: "status" },
      { title: "Created by", field: "user.email" },
      { title: "", render: renderDownloadLink },
    ],
    [],
  )

  const exportRecords: Array<ExportModelType> = useMemo(() => {
    return data?.exports.records || []
  }, [data])

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Exports</Typography>
      </AccordionSummary>
      <MaterialTable
        data={exportRecords}
        localization={{ header: { actions: "" } }}
        style={{ boxShadow: "none" }}
        columns={columns}
        options={{ toolbar: false, sorting: true, filtering: true }}
        isLoading={loading && !data?.exports.records}
      />
    </Accordion>
  )
}

export default observer(BooksTable)
