/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { PhoneNumberModel, PhoneNumberModelType } from "./PhoneNumberModel"
import { PhoneNumberModelSelector } from "./PhoneNumberModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<PhoneNumberModelType>
}

/**
 * PhoneNumberListResponseBase
 * auto generated base class for the model PhoneNumberListResponseModel.
 */
export const PhoneNumberListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("PhoneNumberListResponse")
    .props({
      __typename: types.optional(
        types.literal("PhoneNumberListResponse"),
        "PhoneNumberListResponse",
      ),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => PhoneNumberModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class PhoneNumberListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?:
      | string
      | PhoneNumberModelSelector
      | ((selector: PhoneNumberModelSelector) => PhoneNumberModelSelector),
  ) {
    return this.__child(`records`, PhoneNumberModelSelector, builder)
  }
}
export function selectFromPhoneNumberListResponse() {
  return new PhoneNumberListResponseModelSelector()
}

export const phoneNumberListResponseModelPrimitives = selectFromPhoneNumberListResponse().count
