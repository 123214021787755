import { Button } from "@material-ui/core"
import MaterialTable, { Column } from "material-table"
import React, { useCallback, useMemo } from "react"
import { useStore } from "src/getMstGql"
import { AddressModelType, MutationResponseModelType } from "../../models"
import { rowDatum } from "../../utilities/coercion"
import { displayMutationError, hasMutationErrors } from "../../utilities/errors"
import { TableActions, useModal, useToast } from "../common"
import AddressForm from "./AddressForm"

export type AddressTableProps = {
  addresses: Array<AddressModelType>
  onUpdate?: () => void
  vetId: string
}

const AddressTable: React.FC<AddressTableProps> = ({ addresses, vetId, onUpdate }) => {
  const store = useStore()
  const { openDrawer, closeDrawer, openDialog } = useModal()
  const { setToast } = useToast()

  const columns = useMemo((): Array<Column<AddressModelType>> => {
    return [
      {
        title: "Street",
        field: "streetAddress",
        filtering: true,
      },
      {
        title: "City",
        field: "city",
        filtering: true,
      },
      {
        title: "State",
        field: "state",
        filtering: true,
      },
    ]
  }, [])

  const handleFormComplete = useCallback(
    (updated?: boolean) => {
      closeDrawer()
      if (updated && onUpdate) {
        onUpdate()
      }
    },
    [closeDrawer, onUpdate],
  )

  const handleShowFormClick = useCallback(
    (address?: AddressModelType) => {
      openDrawer(<AddressForm onComplete={handleFormComplete} vetId={vetId} record={address} />)
    },
    [handleFormComplete, openDrawer, vetId],
  )

  const handleDelete = useCallback(
    async (record: AddressModelType) => {
      const response = (await store.mutateDeleteAddress({ id: record.id }).currentPromise()) as {
        deleteAddress: MutationResponseModelType
      }

      if (hasMutationErrors(response)) {
        setToast(displayMutationError(response))
      } else {
        setToast("Deleted address.")
        onUpdate && onUpdate()
      }
    },
    [onUpdate, setToast, store],
  )

  const handleDeleteClick = useCallback(
    (record: AddressModelType) => {
      openDialog({
        title: "Delete this record?",
        body: "This is permanent. Deleted records cannot be restored.",
        onConfirm: () => handleDelete(record),
      })
    },
    [handleDelete, openDialog],
  )

  return (
    <>
      <TableActions>
        <Button variant="contained" onClick={() => handleShowFormClick()}>
          Add
        </Button>
      </TableActions>
      <MaterialTable
        columns={columns}
        data={addresses}
        options={{
          filtering: true,
          toolbar: false,
          pageSize: 5,
        }}
        localization={{ header: { actions: "" } }}
        style={{ boxShadow: "none" }}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, d): void => handleShowFormClick(rowDatum(d)),
          },
          {
            icon: "delete",
            tooltip: "Delete",
            onClick: (event, d): void => handleDeleteClick(rowDatum(d)),
          },
        ]}
      />
    </>
  )
}

export default AddressTable
