import React from "react"
import { Box, BoxProps, CircularProgress } from "@material-ui/core"

export type LoadingContainerProps = BoxProps & {
  loading?: boolean
}

const LoadingContainer: React.FC<LoadingContainerProps> = ({ children, loading, ...props }) => {
  if (loading) {
    return (
      <Box width="100%" textAlign="center" paddingY={2} {...props}>
        <CircularProgress />
      </Box>
    )
  }

  return <>{children}</>
}

export default LoadingContainer
