import { Button } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Page, RequiresRole, useModal, useToast } from "../../components"
import {
  ActionButtonContainer,
  FieldGroup,
  PageHeader,
  ReadOnlyForm,
  ReadOnlyTextField,
} from "../../components/common"
import { LoadingContainer, NotFound } from "../../components/common/utils"
import GenerateExportForm from "../../components/exports/GenerateExportForm"
import VetsTable from "../../components/vets/VetsTable"
import { Paths } from "../../constants/routes"
import { useStore } from "../../getMstGql"
import { ClientModelType, MutationResponseModelType, UserRole } from "../../models"
import { useQuery } from "../../models/reactUtils"
import { displayMutationError, hasMutationErrors } from "../../utilities/errors"

export interface ClientDetailsPageParams {
  id: string
}

const ClientDetailsPage = () => {
  const { id } = useParams<ClientDetailsPageParams>()
  const { loading, data } = useQuery<{ client: ClientModelType }>((store) =>
    store.queryClient({ id }),
  )
  const { mutateDeleteClient } = useStore()
  const history = useHistory()
  const { setToast } = useToast()
  const { openDrawer, closeDrawer, openDialog } = useModal()

  const client = useMemo(() => {
    return data?.client
  }, [data])

  const handleDeleteClient = useCallback(async () => {
    const response = (await mutateDeleteClient({ id }).currentPromise()) as {
      deleteClient: MutationResponseModelType
    }

    if (hasMutationErrors(response)) {
      setToast(displayMutationError(response))
    } else {
      setToast("Deleted client.")
      history.push(Paths.ClientsList)
    }
  }, [id, mutateDeleteClient, setToast, history])

  const handleExportComplete = useCallback(() => {
    closeDrawer()
  }, [closeDrawer])

  const handleExportClick = useCallback(() => {
    if (!client) return
    openDrawer(
      <GenerateExportForm
        onComplete={handleExportComplete}
        filters={{ clientId: client.id }}
        defaultTitle={`${client.name} Export`}
      />,
    )
  }, [handleExportComplete, openDrawer, client])

  if (loading && !client) {
    return <LoadingContainer loading={loading} />
  }

  if (!client) {
    return <NotFound />
  }

  return (
    <LoadingContainer loading={loading && !client}>
      <Page
        header={
          <PageHeader
            title={client.name || ""}
            breadcrumbs={[
              { link: Paths.ClientsList, label: "Clients" },
              { label: client.name || "" },
            ]}
          >
            <ActionButtonContainer>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: 7 }}
                onClick={handleExportClick}
              >
                Export
              </Button>
              <RequiresRole role={UserRole.ADMIN}>
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ marginRight: 7 }}
                  onClick={() => {
                    openDialog({
                      title: "Delete this record?",
                      body: "This is permanent. Deleted records cannot be restored.",
                      onConfirm: handleDeleteClient,
                    })
                  }}
                >
                  Delete
                </Button>
              </RequiresRole>
            </ActionButtonContainer>
          </PageHeader>
        }
      >
        <ReadOnlyForm title="General">
          <FieldGroup>
            <ReadOnlyTextField label="Title" value={client.name} />
          </FieldGroup>
        </ReadOnlyForm>

        <VetsTable filters={{ clientId: client.id }} />
      </Page>
    </LoadingContainer>
  )
}

export default observer(ClientDetailsPage)
