import { InputAdornment, Link, TextField, TextFieldProps, useTheme } from "@material-ui/core"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import PageviewIcon from "@material-ui/icons/Pageview"
import * as React from "react"
import { useMemo } from "react"
import { Link as InternalLink } from "react-router-dom"

export type ReadOnlyTextFieldProps = TextFieldProps & {
  link?: string
  linkIcon?: React.ReactNode
}

const ReadOnlyTextField: React.FC<ReadOnlyTextFieldProps> = ({ link, linkIcon, ...rest }) => {
  const theme = useTheme()

  const isRelativeLink = useMemo(() => {
    return link?.startsWith("/")
  }, [link])

  const endAdornment: React.ReactNode | undefined = link ? (
    isRelativeLink ? (
      <InternalLink to={link}>
        <InputAdornment
          position="end"
          style={{ cursor: "pointer", color: theme.palette.secondary.main }}
        >
          {linkIcon ?? <PageviewIcon />}
        </InputAdornment>
      </InternalLink>
    ) : (
      <Link href={link} target="_blank">
        <InputAdornment
          position="end"
          style={{ cursor: "pointer", color: theme.palette.secondary.main }}
        >
          {linkIcon ?? <OpenInNewIcon />}
        </InputAdornment>
      </Link>
    )
  ) : undefined

  return (
    <TextField
      disabled
      InputProps={{
        readOnly: true,
        style: { color: theme.palette.text.primary },
        endAdornment,
      }}
      {...rest}
    />
  )
}

export default ReadOnlyTextField
