import { Accordion, AccordionSummary, Typography } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import MaterialTable, { Column, Options, Query as TableQuery, QueryResult } from "material-table"
import { observer } from "mobx-react-lite"
import { Query } from "mst-gql"
import * as React from "react"
import { useStore } from "../../getMstGql"
import {
  ClientListResponseModelType,
  ClientModelType,
  clientsSelector,
  RootStoreType,
} from "../../models"
import { rowDatum } from "../../utilities/coercion"
import { extractPaginationArgs } from "../../utilities/tables"
import { useTableCacheKey } from "../common/utils/table/useTableCacheKey"

type QueryResponseType = { clients: ClientListResponseModelType }

function buildQuery(
  store: RootStoreType,
  tableQuery: TableQuery<ClientModelType>,
): Query<QueryResponseType> {
  return store.queryClients(
    {
      pagination: extractPaginationArgs(tableQuery),
    },
    clientsSelector,
  )
}

export type ClientsTableProps = {
  filters?: {}
  tableOptions?: Options<ClientModelType>
  onRowClick?: (row: ClientModelType) => void
  cacheKey?: string | number
}

const ClientsTable: React.FC<ClientsTableProps> = ({ onRowClick, cacheKey, tableOptions = {} }) => {
  const store = useStore()
  const tableRef = useTableCacheKey(cacheKey)
  const handleDetailsClick = React.useCallback(
    (row: ClientModelType): void => onRowClick && onRowClick(row),
    [onRowClick],
  )

  const handleTableUpdate = React.useCallback(
    async (query: TableQuery<ClientModelType>): Promise<QueryResult<ClientModelType>> => {
      const results = await buildQuery(store, query)
      return {
        data: results?.clients?.records?.toJS() || [],
        totalCount: results?.clients?.count || 0,
        page: query.page,
      }
    },
    [store],
  )

  const combinedTableOptions: Options<ClientModelType> = React.useMemo(
    () => ({
      filtering: true,
      toolbar: false,
      debounceInterval: 300,
      pageSize: 10,
      ...tableOptions,
    }),
    [tableOptions],
  )

  const columns = React.useMemo(
    (): Array<Column<ClientModelType>> => [
      {
        title: "Name",
        field: "name",
        filtering: true,
      },
    ],
    [],
  )

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Clients</Typography>
      </AccordionSummary>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={handleTableUpdate}
        options={combinedTableOptions}
        localization={{ header: { actions: "" } }}
        style={{ boxShadow: "none" }}
        actions={[
          {
            icon: "pageview",
            tooltip: "View details",
            onClick: (event, d): void => handleDetailsClick(rowDatum(d)),
          },
        ]}
      />
    </Accordion>
  )
}

export default observer(ClientsTable)
