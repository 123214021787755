import { Button } from "@material-ui/core"
import MaterialTable, { Column } from "material-table"
import React, { useCallback, useMemo } from "react"
import { useStore } from "src/getMstGql"
import { AliasModelType, EntityType } from "../../models"
import { rowDatum } from "../../utilities/coercion"
import { displayMutationError, hasMutationErrors } from "../../utilities/errors"
import { TableActions, useModal, useToast } from "../common"
import AliasForm from "./AliasForm"

type RecordType = AliasModelType
const RECORD_NAME = "alias"

export type AliasTableProps = {
  records: Array<RecordType>
  onUpdate?: () => void
  vetId: string
  entityType: EntityType
}

const AliasTable: React.FC<AliasTableProps> = ({ entityType, records, vetId, onUpdate }) => {
  const store = useStore()
  const { openDrawer, closeDrawer, openDialog } = useModal()
  const { setToast } = useToast()

  const columns = useMemo((): Array<Column<RecordType>> => {
    return entityType === EntityType.Person
      ? [
          {
            title: "First Name",
            field: "firstName",
            filtering: true,
          },
          {
            title: "Middle name",
            field: "middleName",
            filtering: true,
          },
          {
            title: "Last name",
            field: "lastName",
            filtering: true,
          },
        ]
      : [
          {
            title: "Name",
            field: "firstName",
            filtering: true,
          },
        ]
  }, [entityType])

  const handleFormComplete = useCallback(
    (updated?: boolean) => {
      closeDrawer()
      if (updated && onUpdate) {
        onUpdate()
      }
    },
    [closeDrawer, onUpdate],
  )

  const handleShowFormClick = useCallback(
    (record?: RecordType) => {
      openDrawer(
        <AliasForm
          onComplete={handleFormComplete}
          vetId={vetId}
          record={record}
          entityType={entityType}
        />,
      )
    },
    [entityType, handleFormComplete, openDrawer, vetId],
  )

  const handleDelete = useCallback(
    async (record: RecordType) => {
      const response = await store.mutateDeleteAlias({ id: record.id }).currentPromise()

      if (hasMutationErrors(response)) {
        setToast(displayMutationError(response))
      } else {
        setToast(`Deleted ${RECORD_NAME}.`)
        onUpdate && onUpdate()
      }
    },
    [onUpdate, setToast, store],
  )

  const handleDeleteClick = useCallback(
    (record: RecordType) => {
      openDialog({
        title: "Delete this record?",
        body: "This is permanent. Deleted records cannot be restored.",
        onConfirm: () => handleDelete(record),
      })
    },
    [handleDelete, openDialog],
  )

  return (
    <>
      <TableActions>
        <Button variant="contained" onClick={() => handleShowFormClick()}>
          Add
        </Button>
      </TableActions>
      <MaterialTable
        columns={columns}
        data={records}
        options={{
          filtering: true,
          toolbar: false,
          pageSize: 5,
        }}
        localization={{ header: { actions: "" } }}
        style={{ boxShadow: "none" }}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, d): void => handleShowFormClick(rowDatum(d)),
          },
          {
            icon: "delete",
            tooltip: "Delete",
            onClick: (event, d): void => handleDeleteClick(rowDatum(d)),
          },
        ]}
      />
    </>
  )
}

export default AliasTable
