/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FlagModel, FlagModelType } from "./FlagModel"
import { FlagModelSelector } from "./FlagModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  flag: FlagModelType
}

/**
 * FlagUpdateResponseBase
 * auto generated base class for the model FlagUpdateResponseModel.
 */
export const FlagUpdateResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("FlagUpdateResponse")
    .props({
      __typename: types.optional(types.literal("FlagUpdateResponse"), "FlagUpdateResponse"),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      flag: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => FlagModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class FlagUpdateResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  flag(
    builder?: string | FlagModelSelector | ((selector: FlagModelSelector) => FlagModelSelector),
  ) {
    return this.__child(`flag`, FlagModelSelector, builder)
  }
}
export function selectFromFlagUpdateResponse() {
  return new FlagUpdateResponseModelSelector()
}

export const flagUpdateResponseModelPrimitives = selectFromFlagUpdateResponse().success.message
