import * as React from "react"
import { Page, PageHeader } from "src/components"
import ExportsTable from "../../components/exports/ExportsTable"

const ExportsListPage: React.FC = () => {
  return (
    <Page header={<PageHeader title="Books" breadcrumbs={[{ label: "Books" }]} />}>
      <ExportsTable />
    </Page>
  )
}

export default ExportsListPage
