import { Box, BoxProps, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

const useStyles = makeStyles((theme: Theme) => ({
  tableActions: {
    borderWidth: "1px 0px",
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    padding: "8px 16px",
    textAlign: "left",
    "& button": {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      "&:first-child": {
        marginLeft: 0,
      },
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
}))

const TableActions: React.FC<BoxProps> = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <Box className={classes.tableActions} {...props}>
      {children}
    </Box>
  )
}

export default TableActions
