import { Button, Card, CardActions, CardContent, makeStyles, Typography } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import React from "react"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
}))

const NotFound: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Not Found :(
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          The page you were looking for does not exist.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" onClick={(): void => history.goBack()}>
          Go back
        </Button>
      </CardActions>
    </Card>
  )
}

export default NotFound
