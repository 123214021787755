/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AliasModel, AliasModelType } from "./AliasModel"
import { AliasModelSelector } from "./AliasModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<AliasModelType>
}

/**
 * AliasListResponseBase
 * auto generated base class for the model AliasListResponseModel.
 */
export const AliasListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("AliasListResponse")
    .props({
      __typename: types.optional(types.literal("AliasListResponse"), "AliasListResponse"),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => AliasModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class AliasListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?: string | AliasModelSelector | ((selector: AliasModelSelector) => AliasModelSelector),
  ) {
    return this.__child(`records`, AliasModelSelector, builder)
  }
}
export function selectFromAliasListResponse() {
  return new AliasListResponseModelSelector()
}

export const aliasListResponseModelPrimitives = selectFromAliasListResponse().count
