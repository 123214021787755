import { Box, BoxProps, Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { PageContents } from "./index"

export type PageProps = BoxProps & {
  header: React.ReactElement
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  header: {},
  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflowY: "auto",
  },
}))

const Page: React.FC<PageProps> = ({ children, header, ...props }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root} {...props}>
      <Box className={classes.header}>{header}</Box>
      <Box className={classes.content}>
        <PageContents>{children}</PageContents>
      </Box>
    </Box>
  )
}

export default Page
