/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ExportModel, ExportModelType } from "./ExportModel"
import { ExportModelSelector } from "./ExportModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<ExportModelType>
}

/**
 * ExportListResponseBase
 * auto generated base class for the model ExportListResponseModel.
 */
export const ExportListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("ExportListResponse")
    .props({
      __typename: types.optional(types.literal("ExportListResponse"), "ExportListResponse"),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => ExportModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class ExportListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?:
      | string
      | ExportModelSelector
      | ((selector: ExportModelSelector) => ExportModelSelector),
  ) {
    return this.__child(`records`, ExportModelSelector, builder)
  }
}
export function selectFromExportListResponse() {
  return new ExportListResponseModelSelector()
}

export const exportListResponseModelPrimitives = selectFromExportListResponse().count
