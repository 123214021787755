import { Alert } from "@material-ui/lab"
import React from "react"
import { Box, BoxProps } from "@material-ui/core"

export type FormErrorsProps = BoxProps & {
  error?: string
  hidden?: boolean
}

const FormErrors: React.FC<FormErrorsProps> = ({ hidden, error, ...props }) => {
  return (
    <Box marginBottom={2} {...props} hidden={hidden || !error}>
      <Alert severity="error">{error}</Alert>
    </Box>
  )
}

export default FormErrors
