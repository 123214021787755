/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { VetModel, VetModelType } from "./VetModel"
import { VetModelSelector } from "./VetModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  vet: VetModelType
}

/**
 * AliasBase
 * auto generated base class for the model AliasModel.
 */
export const AliasModelBase = withTypedRefs<Refs>()(
  ModelBase.named("Alias")
    .props({
      __typename: types.optional(types.literal("Alias"), "Alias"),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      firstName: types.union(types.undefined, types.string),
      lastName: types.union(types.undefined, types.null, types.string),
      middleName: types.union(types.undefined, types.null, types.string),
      vetId: types.union(types.undefined, types.string),
      vet: types.union(types.undefined, MSTGQLRef(types.late((): any => VetModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class AliasModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get firstName() {
    return this.__attr(`firstName`)
  }
  get lastName() {
    return this.__attr(`lastName`)
  }
  get middleName() {
    return this.__attr(`middleName`)
  }
  get vetId() {
    return this.__attr(`vetId`)
  }
  vet(builder?: string | VetModelSelector | ((selector: VetModelSelector) => VetModelSelector)) {
    return this.__child(`vet`, VetModelSelector, builder)
  }
}
export function selectFromAlias() {
  return new AliasModelSelector()
}

export const aliasModelPrimitives = selectFromAlias().createdAt.updatedAt.firstName.lastName
  .middleName.vetId
