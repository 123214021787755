import { Box, BoxProps, Theme, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Variant } from "@material-ui/core/styles/createTypography"
import React from "react"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
}))

export type FormSectionProps = BoxProps & {
  title: string
  typeVariant?: Variant
}

const FormSection: React.FC<FormSectionProps> = ({ children, title, typeVariant, ...props }) => {
  const styles = useStyles()
  return (
    <Box className={styles.container} {...props}>
      <Typography variant={typeVariant ?? "h6"}>{title}</Typography>
      <Box>{children}</Box>
    </Box>
  )
}

export default FormSection
