import * as React from "react"
import { useCallback, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  ActionButtonContainer,
  AddTagForm,
  Button,
  Page,
  PageHeader,
  RequiresRole,
  TagsTable,
  useModal,
} from "src/components"
import { TagModelType, UserRole } from "../../models"

const TagsListPage: React.FC = () => {
  const history = useHistory()
  const { openDrawer, closeDrawer } = useModal()
  const [cacheKey, setCacheKey] = useState(Date.now())
  const updateCacheKey = useCallback(() => setCacheKey(Date.now()), [setCacheKey])
  const handleAddTag = useCallback(
    (willNavigate?: boolean) => {
      if (!willNavigate) {
        updateCacheKey()
      }
      closeDrawer()
    },
    [closeDrawer, updateCacheKey],
  )
  const handleRowClick = React.useCallback(
    (row: TagModelType): void => {
      history.push(`/tags/${row.id}`)
    },
    [history],
  )

  return (
    <Page
      header={
        <PageHeader title="Tags" breadcrumbs={[{ label: "Tags" }]}>
          <RequiresRole role={UserRole.ADMIN}>
            <ActionButtonContainer>
              <Button
                variant="contained"
                color="primary"
                onClick={() => openDrawer(<AddTagForm onComplete={handleAddTag} />)}
              >
                Create
              </Button>
            </ActionButtonContainer>
          </RequiresRole>
        </PageHeader>
      }
    >
      <TagsTable onRowClick={handleRowClick} cacheKey={cacheKey} />
    </Page>
  )
}

export default TagsListPage
