import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter"
import FlagIcon from "@material-ui/icons/Flag"
import GetAppIcon from "@material-ui/icons/GetApp"
import LabelIcon from "@material-ui/icons/Label"
import PeopleIcon from "@material-ui/icons/People"
import * as React from "react"
import { UserRole } from "../models"

export enum Paths {
  Login = "/login",
  UsersList = "/users",
  UserDetails = "/users/:id",
  UserProfile = "/profile",
  TagsList = "/tags",
  TagDetails = "/tags/:id",
  ClientsList = "/clients",
  ClientDetails = "/clients/:id",
  FlagsList = "/flags",
  ExportsList = "/exports",
  VetsList = "/vets",
  VetDetails = "/vets/:id",
  VetCreate = "/vets/new",
}

export const POST_LOGIN_PATH = Paths.VetsList

export type NavigationItem = {
  path: Paths
  title: string
  icon: React.ComponentType
  requiredRole?: UserRole
}
export const NAVIGATION_ITEMS: Array<NavigationItem> = [
  { path: Paths.VetsList, title: "Vets", icon: AssignmentIndIcon },
  { path: Paths.FlagsList, title: "Flags", icon: FlagIcon },
  { path: Paths.ExportsList, title: "Exports", icon: GetAppIcon },
  { path: Paths.ClientsList, title: "Clients", icon: BusinessCenterIcon },
  { path: Paths.TagsList, title: "Tags", icon: LabelIcon },
  { path: Paths.UsersList, title: "Users", icon: PeopleIcon, requiredRole: UserRole.ADMIN },
]
