import { Button } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { useHistory, useParams } from "react-router-dom"
import { RequiresRole, useModal, useToast } from "../../components"
import {
  ActionButtonContainer,
  FieldGroup,
  Page,
  PageHeader,
  ReadOnlyForm,
  ReadOnlyTextField,
} from "../../components/common"
import { LoadingContainer, NotFound } from "../../components/common/utils"
import { Paths } from "../../constants/routes"
import { useStore } from "../../getMstGql"
import { MutationResponseModelType, TagModelType, UserRole } from "../../models"
import { useQuery } from "../../models/reactUtils"
import { displayMutationError, hasMutationErrors } from "../../utilities/errors"

export interface TagDetailsPageParams {
  id: string
}

const TagDetailsPage = () => {
  const { id } = useParams<TagDetailsPageParams>()
  const { loading, data } = useQuery<{ tag: TagModelType }>((store) => store.queryTag({ id }))
  const { mutateDeleteTag } = useStore()
  const history = useHistory()
  const { setToast } = useToast()
  const { openDialog } = useModal()

  const tag = useMemo(() => {
    return data?.tag
  }, [data])

  const handleDeleteTag = useCallback(async () => {
    const response = (await mutateDeleteTag({ id }).currentPromise()) as {
      deleteTag: MutationResponseModelType
    }

    if (hasMutationErrors(response)) {
      setToast(displayMutationError(response))
    } else {
      setToast("Deleted tag.")
      history.push(Paths.TagsList)
    }
  }, [id, mutateDeleteTag, setToast, history])

  if (loading && !tag) {
    return <LoadingContainer loading={loading} />
  }

  if (!tag) {
    return <NotFound />
  }

  return (
    <LoadingContainer loading={loading && !tag}>
      <Page
        header={
          <PageHeader
            title={tag.name || ""}
            breadcrumbs={[{ link: Paths.TagsList, label: "Tags" }, { label: tag.name || "" }]}
          >
            <RequiresRole role={UserRole.ADMIN}>
              <ActionButtonContainer>
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ marginRight: 7 }}
                  onClick={() => {
                    openDialog({
                      title: "Delete this record?",
                      body: "This is permanent. Deleted records cannot be restored.",
                      onConfirm: handleDeleteTag,
                    })
                  }}
                >
                  Delete
                </Button>
              </ActionButtonContainer>
            </RequiresRole>
          </PageHeader>
        }
      >
        <ReadOnlyForm title="General">
          <FieldGroup>
            <ReadOnlyTextField label="Title" value={tag.name} />
          </FieldGroup>
        </ReadOnlyForm>
      </Page>
    </LoadingContainer>
  )
}

export default observer(TagDetailsPage)
