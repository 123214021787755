/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FlagModel, FlagModelType } from "./FlagModel"
import { FlagModelSelector } from "./FlagModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<FlagModelType>
}

/**
 * FlagListResponseBase
 * auto generated base class for the model FlagListResponseModel.
 */
export const FlagListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("FlagListResponse")
    .props({
      __typename: types.optional(types.literal("FlagListResponse"), "FlagListResponse"),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => FlagModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class FlagListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?: string | FlagModelSelector | ((selector: FlagModelSelector) => FlagModelSelector),
  ) {
    return this.__child(`records`, FlagModelSelector, builder)
  }
}
export function selectFromFlagListResponse() {
  return new FlagListResponseModelSelector()
}

export const flagListResponseModelPrimitives = selectFromFlagListResponse().count
