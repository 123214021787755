/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AddressModel, AddressModelType } from "./AddressModel"
import { AddressModelSelector } from "./AddressModel.base"
import { AliasModel, AliasModelType } from "./AliasModel"
import { AliasModelSelector } from "./AliasModel.base"
import { EmailModel, EmailModelType } from "./EmailModel"
import { EmailModelSelector } from "./EmailModel.base"
import { EntityTypeEnumType } from "./EntityTypeEnum"
import { FlagModel, FlagModelType } from "./FlagModel"
import { FlagModelSelector } from "./FlagModel.base"
import { PhoneNumberModel, PhoneNumberModelType } from "./PhoneNumberModel"
import { PhoneNumberModelSelector } from "./PhoneNumberModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  flags: IObservableArray<FlagModelType>
  emails: IObservableArray<EmailModelType>
  addresses: IObservableArray<AddressModelType>
  phoneNumbers: IObservableArray<PhoneNumberModelType>
  aliases: IObservableArray<AliasModelType>
}

/**
 * VetBase
 * auto generated base class for the model VetModel.
 */
export const VetModelBase = withTypedRefs<Refs>()(
  ModelBase.named("Vet")
    .props({
      __typename: types.optional(types.literal("Vet"), "Vet"),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      entityType: types.union(types.undefined, EntityTypeEnumType),
      firstName: types.union(types.undefined, types.string),
      lastName: types.union(types.undefined, types.null, types.string),
      middleName: types.union(types.undefined, types.null, types.string),
      title: types.union(types.undefined, types.null, types.string),
      flags: types.union(types.undefined, types.array(MSTGQLRef(types.late((): any => FlagModel)))),
      emails: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => EmailModel))),
      ),
      addresses: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => AddressModel))),
      ),
      phoneNumbers: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => PhoneNumberModel))),
      ),
      aliases: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => AliasModel))),
      ),
      name: types.union(types.undefined, types.string),
      flagCount: types.union(types.undefined, types.integer),
      clientIds: types.union(types.undefined, types.array(types.string)),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class VetModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get entityType() {
    return this.__attr(`entityType`)
  }
  get firstName() {
    return this.__attr(`firstName`)
  }
  get lastName() {
    return this.__attr(`lastName`)
  }
  get middleName() {
    return this.__attr(`middleName`)
  }
  get title() {
    return this.__attr(`title`)
  }
  get name() {
    return this.__attr(`name`)
  }
  get flagCount() {
    return this.__attr(`flagCount`)
  }
  get clientIds() {
    return this.__attr(`clientIds`)
  }
  flags(
    builder?: string | FlagModelSelector | ((selector: FlagModelSelector) => FlagModelSelector),
  ) {
    return this.__child(`flags`, FlagModelSelector, builder)
  }
  emails(
    builder?: string | EmailModelSelector | ((selector: EmailModelSelector) => EmailModelSelector),
  ) {
    return this.__child(`emails`, EmailModelSelector, builder)
  }
  addresses(
    builder?:
      | string
      | AddressModelSelector
      | ((selector: AddressModelSelector) => AddressModelSelector),
  ) {
    return this.__child(`addresses`, AddressModelSelector, builder)
  }
  phoneNumbers(
    builder?:
      | string
      | PhoneNumberModelSelector
      | ((selector: PhoneNumberModelSelector) => PhoneNumberModelSelector),
  ) {
    return this.__child(`phoneNumbers`, PhoneNumberModelSelector, builder)
  }
  aliases(
    builder?: string | AliasModelSelector | ((selector: AliasModelSelector) => AliasModelSelector),
  ) {
    return this.__child(`aliases`, AliasModelSelector, builder)
  }
}
export function selectFromVet() {
  return new VetModelSelector()
}

export const vetModelPrimitives = selectFromVet().createdAt.updatedAt.entityType.firstName.lastName
  .middleName.title.name.flagCount.clientIds
