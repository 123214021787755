import { Theme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // padding: theme.spacing(3),
    flex: 1,
    overflowY: "scroll",
    height: "100%",
  },
}))

const ScrollablePageContents: React.FC = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

export default ScrollablePageContents
