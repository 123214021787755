import {
  AppBar,
  Box,
  BoxProps,
  Divider,
  Drawer,
  IconButton,
  List,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import MenuIcon from "@material-ui/icons/Menu"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"
import { ErrorBoundary } from "src/components"
import {
  NAV_DRAWER_DEFAULT_OPEN,
  NAV_DRAWER_DEFAULT_OPEN_KEY,
  NAV_DRAWER_WIDTH,
} from "src/constants/interface"
import { APP_NAME } from "src/constants/names"
import { NAVIGATION_ITEMS } from "src/constants/routes"
import { useStore } from "../../../getMstGql"
import { AppBarMenu, NavigationItem } from "./"

export type LayoutProps = BoxProps

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: NAV_DRAWER_WIDTH,
    width: `calc(100% - ${NAV_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: NAV_DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    maxHeight: "100vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.mixins.toolbar.minHeight,
  },
}))

const Layout: React.FC<LayoutProps> = ({ children, ...props }) => {
  const [open, setOpen] = React.useState(NAV_DRAWER_DEFAULT_OPEN)
  const { authentication } = useStore()

  const handleDrawerOpen = (): void => {
    window.localStorage.setItem(NAV_DRAWER_DEFAULT_OPEN_KEY, true.toString())
    setOpen(true)
  }
  const handleDrawerClose = (): void => {
    window.localStorage.setItem(NAV_DRAWER_DEFAULT_OPEN_KEY, false.toString())
    setOpen(false)
  }

  const classes = useStyles()
  const navItems = React.useMemo(() => {
    return NAVIGATION_ITEMS.filter((navItem) =>
      navItem.requiredRole ? authentication.hasRole(navItem.requiredRole) : true,
    )
  }, [authentication])
  return (
    <Box className={classes.root} {...props}>
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {APP_NAME}
          </Typography>
          <AppBarMenu />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {navItems.map((navItem) => (
            <NavigationItem
              key={navItem.path}
              icon={navItem.icon}
              path={navItem.path}
              title={navItem.title}
            />
          ))}
        </List>
      </Drawer>
      <ErrorBoundary>
        <main className={classes.content}>{children}</main>
      </ErrorBoundary>
    </Box>
  )
}

export default observer(Layout)
