/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum FlagSource {
  CampaignFinance = "CampaignFinance",
  CourtRecords = "CourtRecords",
  Facebook = "Facebook",
  Instagram = "Instagram",
  Lexis = "Lexis",
  LobbyingDisclosure = "LobbyingDisclosure",
  Nexis = "Nexis",
  Twitter = "Twitter",
  Other = "Other",
}

/**
 * FlagSource
 */
export const FlagSourceEnumType = types.enumeration("FlagSource", [
  "CampaignFinance",
  "CourtRecords",
  "Facebook",
  "Instagram",
  "Lexis",
  "LobbyingDisclosure",
  "Nexis",
  "Twitter",
  "Other",
])
