/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AliasModel, AliasModelType } from "./AliasModel"
import { AliasModelSelector } from "./AliasModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  alias: AliasModelType
}

/**
 * AliasCreationResponseBase
 * auto generated base class for the model AliasCreationResponseModel.
 */
export const AliasCreationResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("AliasCreationResponse")
    .props({
      __typename: types.optional(types.literal("AliasCreationResponse"), "AliasCreationResponse"),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      alias: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => AliasModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class AliasCreationResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  alias(
    builder?: string | AliasModelSelector | ((selector: AliasModelSelector) => AliasModelSelector),
  ) {
    return this.__child(`alias`, AliasModelSelector, builder)
  }
}
export function selectFromAliasCreationResponse() {
  return new AliasCreationResponseModelSelector()
}

export const aliasCreationResponseModelPrimitives = selectFromAliasCreationResponse().success
  .message
