import React from "react"
import { Avatar, Box, Container, Theme, Typography } from "@material-ui/core"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import { makeStyles } from "@material-ui/core/styles"
import { LoginUserForm } from "../components/users"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(8),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}))

const Login: React.FC = () => {
  const styles = useStyles()
  return (
    <Container maxWidth="xs" className={styles.container}>
      <Box display="flex" flexDirection="column" alignItems="center" marginBottom={2}>
        <Avatar className={styles.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
      </Box>
      <LoginUserForm />
    </Container>
  )
}

export default Login
