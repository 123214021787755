/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ExportModel, ExportModelType } from "./ExportModel"
import { ExportModelSelector } from "./ExportModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  book: ExportModelType
}

/**
 * GenerateExportMutationResponseBase
 * auto generated base class for the model GenerateExportMutationResponseModel.
 */
export const GenerateExportMutationResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("GenerateExportMutationResponse")
    .props({
      __typename: types.optional(
        types.literal("GenerateExportMutationResponse"),
        "GenerateExportMutationResponse",
      ),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      book: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => ExportModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class GenerateExportMutationResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  book(
    builder?:
      | string
      | ExportModelSelector
      | ((selector: ExportModelSelector) => ExportModelSelector),
  ) {
    return this.__child(`book`, ExportModelSelector, builder)
  }
}
export function selectFromGenerateExportMutationResponse() {
  return new GenerateExportMutationResponseModelSelector()
}

export const generateExportMutationResponseModelPrimitives = selectFromGenerateExportMutationResponse()
  .success.message
