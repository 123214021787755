/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { ObservableMap } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLStore, configureStoreMixin, QueryOptions, withTypedRefs } from "mst-gql"

import { ObjectPropertyModel, ObjectPropertyModelType } from "./ObjectPropertyModel"
import {
  objectPropertyModelPrimitives,
  ObjectPropertyModelSelector,
} from "./ObjectPropertyModel.base"
import { AddressModel, AddressModelType } from "./AddressModel"
import { addressModelPrimitives, AddressModelSelector } from "./AddressModel.base"
import { VetModel, VetModelType } from "./VetModel"
import { vetModelPrimitives, VetModelSelector } from "./VetModel.base"
import { FlagModel, FlagModelType } from "./FlagModel"
import { flagModelPrimitives, FlagModelSelector } from "./FlagModel.base"
import { TagModel, TagModelType } from "./TagModel"
import { tagModelPrimitives, TagModelSelector } from "./TagModel.base"
import { EmailModel, EmailModelType } from "./EmailModel"
import { emailModelPrimitives, EmailModelSelector } from "./EmailModel.base"
import { PhoneNumberModel, PhoneNumberModelType } from "./PhoneNumberModel"
import { phoneNumberModelPrimitives, PhoneNumberModelSelector } from "./PhoneNumberModel.base"
import { AliasModel, AliasModelType } from "./AliasModel"
import { aliasModelPrimitives, AliasModelSelector } from "./AliasModel.base"
import { AddressListResponseModel, AddressListResponseModelType } from "./AddressListResponseModel"
import {
  addressListResponseModelPrimitives,
  AddressListResponseModelSelector,
} from "./AddressListResponseModel.base"
import { AliasListResponseModel, AliasListResponseModelType } from "./AliasListResponseModel"
import {
  aliasListResponseModelPrimitives,
  AliasListResponseModelSelector,
} from "./AliasListResponseModel.base"
import { ClientModel, ClientModelType } from "./ClientModel"
import { clientModelPrimitives, ClientModelSelector } from "./ClientModel.base"
import { ClientListResponseModel, ClientListResponseModelType } from "./ClientListResponseModel"
import {
  clientListResponseModelPrimitives,
  ClientListResponseModelSelector,
} from "./ClientListResponseModel.base"
import { VetListResponseModel, VetListResponseModelType } from "./VetListResponseModel"
import {
  vetListResponseModelPrimitives,
  VetListResponseModelSelector,
} from "./VetListResponseModel.base"
import { EmailListResponseModel, EmailListResponseModelType } from "./EmailListResponseModel"
import {
  emailListResponseModelPrimitives,
  EmailListResponseModelSelector,
} from "./EmailListResponseModel.base"
import { TagListResponseModel, TagListResponseModelType } from "./TagListResponseModel"
import {
  tagListResponseModelPrimitives,
  TagListResponseModelSelector,
} from "./TagListResponseModel.base"
import { FlagListResponseModel, FlagListResponseModelType } from "./FlagListResponseModel"
import {
  flagListResponseModelPrimitives,
  FlagListResponseModelSelector,
} from "./FlagListResponseModel.base"
import {
  PhoneNumberListResponseModel,
  PhoneNumberListResponseModelType,
} from "./PhoneNumberListResponseModel"
import {
  phoneNumberListResponseModelPrimitives,
  PhoneNumberListResponseModelSelector,
} from "./PhoneNumberListResponseModel.base"
import { ExportModel, ExportModelType } from "./ExportModel"
import { exportModelPrimitives, ExportModelSelector } from "./ExportModel.base"
import { UserModel, UserModelType } from "./UserModel"
import { userModelPrimitives, UserModelSelector } from "./UserModel.base"
import { ExportListResponseModel, ExportListResponseModelType } from "./ExportListResponseModel"
import {
  exportListResponseModelPrimitives,
  ExportListResponseModelSelector,
} from "./ExportListResponseModel.base"
import { UserListResponseModel, UserListResponseModelType } from "./UserListResponseModel"
import {
  userListResponseModelPrimitives,
  UserListResponseModelSelector,
} from "./UserListResponseModel.base"
import { UserLoginResponseModel, UserLoginResponseModelType } from "./UserLoginResponseModel"
import {
  userLoginResponseModelPrimitives,
  UserLoginResponseModelSelector,
} from "./UserLoginResponseModel.base"
import { MutationResponseModel, MutationResponseModelType } from "./MutationResponseModel"
import {
  mutationResponseModelPrimitives,
  MutationResponseModelSelector,
} from "./MutationResponseModel.base"
import {
  RefreshTokenResponseModel,
  RefreshTokenResponseModelType,
} from "./RefreshTokenResponseModel"
import {
  refreshTokenResponseModelPrimitives,
  RefreshTokenResponseModelSelector,
} from "./RefreshTokenResponseModel.base"
import {
  AddressCreationResponseModel,
  AddressCreationResponseModelType,
} from "./AddressCreationResponseModel"
import {
  addressCreationResponseModelPrimitives,
  AddressCreationResponseModelSelector,
} from "./AddressCreationResponseModel.base"
import {
  AddressUpdateResponseModel,
  AddressUpdateResponseModelType,
} from "./AddressUpdateResponseModel"
import {
  addressUpdateResponseModelPrimitives,
  AddressUpdateResponseModelSelector,
} from "./AddressUpdateResponseModel.base"
import {
  AliasCreationResponseModel,
  AliasCreationResponseModelType,
} from "./AliasCreationResponseModel"
import {
  aliasCreationResponseModelPrimitives,
  AliasCreationResponseModelSelector,
} from "./AliasCreationResponseModel.base"
import { AliasUpdateResponseModel, AliasUpdateResponseModelType } from "./AliasUpdateResponseModel"
import {
  aliasUpdateResponseModelPrimitives,
  AliasUpdateResponseModelSelector,
} from "./AliasUpdateResponseModel.base"
import {
  ClientCreationResponseModel,
  ClientCreationResponseModelType,
} from "./ClientCreationResponseModel"
import {
  clientCreationResponseModelPrimitives,
  ClientCreationResponseModelSelector,
} from "./ClientCreationResponseModel.base"
import {
  ClientUpdateResponseModel,
  ClientUpdateResponseModelType,
} from "./ClientUpdateResponseModel"
import {
  clientUpdateResponseModelPrimitives,
  ClientUpdateResponseModelSelector,
} from "./ClientUpdateResponseModel.base"
import { VetCreationResponseModel, VetCreationResponseModelType } from "./VetCreationResponseModel"
import {
  vetCreationResponseModelPrimitives,
  VetCreationResponseModelSelector,
} from "./VetCreationResponseModel.base"
import { VetUpdateResponseModel, VetUpdateResponseModelType } from "./VetUpdateResponseModel"
import {
  vetUpdateResponseModelPrimitives,
  VetUpdateResponseModelSelector,
} from "./VetUpdateResponseModel.base"
import {
  EmailCreationResponseModel,
  EmailCreationResponseModelType,
} from "./EmailCreationResponseModel"
import {
  emailCreationResponseModelPrimitives,
  EmailCreationResponseModelSelector,
} from "./EmailCreationResponseModel.base"
import { EmailUpdateResponseModel, EmailUpdateResponseModelType } from "./EmailUpdateResponseModel"
import {
  emailUpdateResponseModelPrimitives,
  EmailUpdateResponseModelSelector,
} from "./EmailUpdateResponseModel.base"
import { TagCreationResponseModel, TagCreationResponseModelType } from "./TagCreationResponseModel"
import {
  tagCreationResponseModelPrimitives,
  TagCreationResponseModelSelector,
} from "./TagCreationResponseModel.base"
import { TagUpdateResponseModel, TagUpdateResponseModelType } from "./TagUpdateResponseModel"
import {
  tagUpdateResponseModelPrimitives,
  TagUpdateResponseModelSelector,
} from "./TagUpdateResponseModel.base"
import {
  FlagCreationResponseModel,
  FlagCreationResponseModelType,
} from "./FlagCreationResponseModel"
import {
  flagCreationResponseModelPrimitives,
  FlagCreationResponseModelSelector,
} from "./FlagCreationResponseModel.base"
import { FlagUpdateResponseModel, FlagUpdateResponseModelType } from "./FlagUpdateResponseModel"
import {
  flagUpdateResponseModelPrimitives,
  FlagUpdateResponseModelSelector,
} from "./FlagUpdateResponseModel.base"
import {
  PhoneNumberCreationResponseModel,
  PhoneNumberCreationResponseModelType,
} from "./PhoneNumberCreationResponseModel"
import {
  phoneNumberCreationResponseModelPrimitives,
  PhoneNumberCreationResponseModelSelector,
} from "./PhoneNumberCreationResponseModel.base"
import {
  PhoneNumberUpdateResponseModel,
  PhoneNumberUpdateResponseModelType,
} from "./PhoneNumberUpdateResponseModel"
import {
  phoneNumberUpdateResponseModelPrimitives,
  PhoneNumberUpdateResponseModelSelector,
} from "./PhoneNumberUpdateResponseModel.base"
import {
  GenerateExportMutationResponseModel,
  GenerateExportMutationResponseModelType,
} from "./GenerateExportMutationResponseModel"
import {
  generateExportMutationResponseModelPrimitives,
  GenerateExportMutationResponseModelSelector,
} from "./GenerateExportMutationResponseModel.base"
import {
  UserCreationResponseModel,
  UserCreationResponseModelType,
} from "./UserCreationResponseModel"
import {
  userCreationResponseModelPrimitives,
  UserCreationResponseModelSelector,
} from "./UserCreationResponseModel.base"
import { UserUpdateResponseModel, UserUpdateResponseModelType } from "./UserUpdateResponseModel"
import {
  userUpdateResponseModelPrimitives,
  UserUpdateResponseModelSelector,
} from "./UserUpdateResponseModel.base"

import { State } from "./StateEnum"
import { EntityType } from "./EntityTypeEnum"
import { FlagSource } from "./FlagSourceEnum"
import { VetOrderColumns } from "./VetOrderColumnsEnum"
import { OrderByDirection } from "./OrderByDirectionEnum"
import { FlagOrderColumns } from "./FlagOrderColumnsEnum"
import { UserRole } from "./UserRoleEnum"
import { BookStatus } from "./BookStatusEnum"

export type PaginationArgs = {
  limit: number
  offset: number
}
export type VetOrderBy = {
  column: VetOrderColumns
  direction: OrderByDirection
}
export type VetFilters = {
  entityType?: EntityType
  firstName?: string
  middleName?: string
  lastName?: string
  email?: string
  phone?: string
  streetAddress?: string
  city?: string
  state?: State
  clientId?: string
}
export type FlagOrderBy = {
  column: FlagOrderColumns
  direction: OrderByDirection
}
export type FlagFilters = {
  vetId?: string
  clientId?: string
  title?: string
  text?: string
  tags?: string[]
  source?: FlagSource
  customSource?: string
  completedAt?: DateRangeInput
  personName?: string
}
export type DateRangeInput = {
  start?: any
  end?: any
}
export type ExportFilters = {
  vetId?: string
}
export type UserLoginArgs = {
  email: string
  password: string
}
export type CreateAddressInput = {
  state: State
  streetAddress: string
  city: string
  vetId: string
}
export type UpdateAddressInput = {
  state?: State
  streetAddress?: string
  city?: string
  vetId?: string
}
export type CreateAliasInput = {
  firstName: string
  lastName?: string
  middleName?: string
  vetId: string
}
export type UpdateAliasInput = {
  firstName?: string
  lastName?: string
  middleName?: string
  vetId?: string
}
export type CreateClientInput = {
  name: string
}
export type UpdateClientInput = {
  name?: string
}
export type CreateVetInput = {
  entityType: EntityType
  firstName: string
  lastName?: string
  middleName?: string
  title?: string
  clientIds?: string[]
}
export type UpdateVetInput = {
  entityType?: EntityType
  firstName?: string
  lastName?: string
  middleName?: string
  title?: string
  clientIds?: string[]
}
export type CreateEmailInput = {
  email: string
  vetId: string
}
export type UpdateEmailInput = {
  email?: string
  vetId?: string
}
export type CreateTagInput = {
  name: string
}
export type UpdateTagInput = {
  name?: string
}
export type CreateFlagInput = {
  url?: string
  customSource?: string
  title: string
  text: string
  image?: string
  completedAt: any
  source: FlagSource
  vetId: string
  tags: string[]
}
export type CreatePhoneNumberInput = {
  number: string
  vetId: string
}
export type UpdatePhoneNumberInput = {
  number?: string
  vetId?: string
}
export type CreateUserInput = {
  email: string
  password: string
  name: string
  role: UserRole
}
export type UpdateUserInput = {
  password?: string
  name?: string
  role?: UserRole
}
/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  addresses: ObservableMap<string, AddressModelType>
  vets: ObservableMap<string, VetModelType>
  flags: ObservableMap<string, FlagModelType>
  tags: ObservableMap<string, TagModelType>
  emails: ObservableMap<string, EmailModelType>
  phoneNumbers: ObservableMap<string, PhoneNumberModelType>
  aliases: ObservableMap<string, AliasModelType>
  clients: ObservableMap<string, ClientModelType>
  exports: ObservableMap<string, ExportModelType>
  users: ObservableMap<string, UserModelType>
}

/**
 * Enums for the names of base graphql actions
 */
export enum RootStoreBaseQueries {
  queryGetObjectProperties = "queryGetObjectProperties",
  queryAddress = "queryAddress",
  queryAddresses = "queryAddresses",
  queryAlias = "queryAlias",
  queryAliases = "queryAliases",
  queryClient = "queryClient",
  queryClients = "queryClients",
  queryVet = "queryVet",
  queryVets = "queryVets",
  queryEmail = "queryEmail",
  queryEmails = "queryEmails",
  queryTag = "queryTag",
  queryTags = "queryTags",
  queryFlag = "queryFlag",
  queryFlags = "queryFlags",
  queryPhoneNumber = "queryPhoneNumber",
  queryPhoneNumbers = "queryPhoneNumbers",
  queryExport = "queryExport",
  queryExports = "queryExports",
  queryUser = "queryUser",
  queryUsers = "queryUsers",
  queryWhoAmI = "queryWhoAmI",
}
export enum RootStoreBaseMutations {
  mutateLogin = "mutateLogin",
  mutateLogout = "mutateLogout",
  mutateRefreshToken = "mutateRefreshToken",
  mutateCreateAddress = "mutateCreateAddress",
  mutateUpdateAddress = "mutateUpdateAddress",
  mutateDeleteAddress = "mutateDeleteAddress",
  mutateCreateAlias = "mutateCreateAlias",
  mutateUpdateAlias = "mutateUpdateAlias",
  mutateDeleteAlias = "mutateDeleteAlias",
  mutateCreateClient = "mutateCreateClient",
  mutateUpdateClient = "mutateUpdateClient",
  mutateDeleteClient = "mutateDeleteClient",
  mutateDeleteVet = "mutateDeleteVet",
  mutateCreateVet = "mutateCreateVet",
  mutateUpdateVet = "mutateUpdateVet",
  mutateCreateEmail = "mutateCreateEmail",
  mutateUpdateEmail = "mutateUpdateEmail",
  mutateDeleteEmail = "mutateDeleteEmail",
  mutateCreateTag = "mutateCreateTag",
  mutateUpdateTag = "mutateUpdateTag",
  mutateDeleteTag = "mutateDeleteTag",
  mutateDeleteFlag = "mutateDeleteFlag",
  mutateCreateFlag = "mutateCreateFlag",
  mutateUpdateFlag = "mutateUpdateFlag",
  mutateCreatePhoneNumber = "mutateCreatePhoneNumber",
  mutateUpdatePhoneNumber = "mutateUpdatePhoneNumber",
  mutateDeletePhoneNumber = "mutateDeletePhoneNumber",
  mutateDeleteExport = "mutateDeleteExport",
  mutateGenerateExport = "mutateGenerateExport",
  mutateDeleteUser = "mutateDeleteUser",
  mutateCreateUser = "mutateCreateUser",
  mutateUpdateUser = "mutateUpdateUser",
}

/**
 * Store, managing, among others, all the objects received through graphQL
 */
export const RootStoreBase = withTypedRefs<Refs>()(
  MSTGQLStore.named("RootStore")
    .extend(
      configureStoreMixin(
        [
          ["ObjectProperty", () => ObjectPropertyModel],
          ["Address", () => AddressModel],
          ["Vet", () => VetModel],
          ["Flag", () => FlagModel],
          ["Tag", () => TagModel],
          ["Email", () => EmailModel],
          ["PhoneNumber", () => PhoneNumberModel],
          ["Alias", () => AliasModel],
          ["AddressListResponse", () => AddressListResponseModel],
          ["AliasListResponse", () => AliasListResponseModel],
          ["Client", () => ClientModel],
          ["ClientListResponse", () => ClientListResponseModel],
          ["VetListResponse", () => VetListResponseModel],
          ["EmailListResponse", () => EmailListResponseModel],
          ["TagListResponse", () => TagListResponseModel],
          ["FlagListResponse", () => FlagListResponseModel],
          ["PhoneNumberListResponse", () => PhoneNumberListResponseModel],
          ["Export", () => ExportModel],
          ["User", () => UserModel],
          ["ExportListResponse", () => ExportListResponseModel],
          ["UserListResponse", () => UserListResponseModel],
          ["UserLoginResponse", () => UserLoginResponseModel],
          ["MutationResponse", () => MutationResponseModel],
          ["RefreshTokenResponse", () => RefreshTokenResponseModel],
          ["AddressCreationResponse", () => AddressCreationResponseModel],
          ["AddressUpdateResponse", () => AddressUpdateResponseModel],
          ["AliasCreationResponse", () => AliasCreationResponseModel],
          ["AliasUpdateResponse", () => AliasUpdateResponseModel],
          ["ClientCreationResponse", () => ClientCreationResponseModel],
          ["ClientUpdateResponse", () => ClientUpdateResponseModel],
          ["VetCreationResponse", () => VetCreationResponseModel],
          ["VetUpdateResponse", () => VetUpdateResponseModel],
          ["EmailCreationResponse", () => EmailCreationResponseModel],
          ["EmailUpdateResponse", () => EmailUpdateResponseModel],
          ["TagCreationResponse", () => TagCreationResponseModel],
          ["TagUpdateResponse", () => TagUpdateResponseModel],
          ["FlagCreationResponse", () => FlagCreationResponseModel],
          ["FlagUpdateResponse", () => FlagUpdateResponseModel],
          ["PhoneNumberCreationResponse", () => PhoneNumberCreationResponseModel],
          ["PhoneNumberUpdateResponse", () => PhoneNumberUpdateResponseModel],
          ["GenerateExportMutationResponse", () => GenerateExportMutationResponseModel],
          ["UserCreationResponse", () => UserCreationResponseModel],
          ["UserUpdateResponse", () => UserUpdateResponseModel],
        ],
        [
          "Address",
          "Vet",
          "Flag",
          "Tag",
          "Email",
          "PhoneNumber",
          "Alias",
          "Client",
          "Export",
          "User",
        ],
        "js",
      ),
    )
    .props({
      addresses: types.optional(types.map(types.late((): any => AddressModel)), {}),
      vets: types.optional(types.map(types.late((): any => VetModel)), {}),
      flags: types.optional(types.map(types.late((): any => FlagModel)), {}),
      tags: types.optional(types.map(types.late((): any => TagModel)), {}),
      emails: types.optional(types.map(types.late((): any => EmailModel)), {}),
      phoneNumbers: types.optional(types.map(types.late((): any => PhoneNumberModel)), {}),
      aliases: types.optional(types.map(types.late((): any => AliasModel)), {}),
      clients: types.optional(types.map(types.late((): any => ClientModel)), {}),
      exports: types.optional(types.map(types.late((): any => ExportModel)), {}),
      users: types.optional(types.map(types.late((): any => UserModel)), {}),
    })
    .actions((self) => ({
      queryGetObjectProperties(
        variables: { objectName: string },
        resultSelector:
          | string
          | ((
              qb: ObjectPropertyModelSelector,
            ) => ObjectPropertyModelSelector) = objectPropertyModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ getObjectProperties: ObjectPropertyModelType[] }>(
          `query getObjectProperties($objectName: String!) { getObjectProperties(objectName: $objectName) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new ObjectPropertyModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryAddress(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: AddressModelSelector,
            ) => AddressModelSelector) = addressModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ address: AddressModelType }>(
          `query address($id: ID!) { address(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new AddressModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryAddresses(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: AddressListResponseModelSelector,
            ) => AddressListResponseModelSelector) = addressListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ addresses: AddressListResponseModelType }>(
          `query addresses($pagination: PaginationArgs) { addresses(pagination: $pagination) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new AddressListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryAlias(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: AliasModelSelector) => AliasModelSelector) = aliasModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ alias: AliasModelType }>(
          `query alias($id: ID!) { alias(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new AliasModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryAliases(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: AliasListResponseModelSelector,
            ) => AliasListResponseModelSelector) = aliasListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ aliases: AliasListResponseModelType }>(
          `query aliases($pagination: PaginationArgs) { aliases(pagination: $pagination) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new AliasListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryClient(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: ClientModelSelector) => ClientModelSelector) = clientModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ client: ClientModelType }>(
          `query client($id: ID!) { client(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new ClientModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryClients(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: ClientListResponseModelSelector,
            ) => ClientListResponseModelSelector) = clientListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ clients: ClientListResponseModelType }>(
          `query clients($pagination: PaginationArgs) { clients(pagination: $pagination) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new ClientListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryVet(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: VetModelSelector) => VetModelSelector) = vetModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ vet: VetModelType }>(
          `query vet($id: ID!) { vet(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new VetModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryVets(
        variables: { orderBy?: VetOrderBy; pagination?: PaginationArgs; filters?: VetFilters },
        resultSelector:
          | string
          | ((
              qb: VetListResponseModelSelector,
            ) => VetListResponseModelSelector) = vetListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ vets: VetListResponseModelType }>(
          `query vets($orderBy: VetOrderBy, $pagination: PaginationArgs, $filters: VetFilters) { vets(orderBy: $orderBy, pagination: $pagination, filters: $filters) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new VetListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryEmail(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: EmailModelSelector) => EmailModelSelector) = emailModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ email: EmailModelType }>(
          `query email($id: ID!) { email(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new EmailModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryEmails(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: EmailListResponseModelSelector,
            ) => EmailListResponseModelSelector) = emailListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ emails: EmailListResponseModelType }>(
          `query emails($pagination: PaginationArgs) { emails(pagination: $pagination) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new EmailListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryTag(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: TagModelSelector) => TagModelSelector) = tagModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ tag: TagModelType }>(
          `query tag($id: ID!) { tag(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new TagModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryTags(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: TagListResponseModelSelector,
            ) => TagListResponseModelSelector) = tagListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ tags: TagListResponseModelType }>(
          `query tags($pagination: PaginationArgs) { tags(pagination: $pagination) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new TagListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryFlag(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: FlagModelSelector) => FlagModelSelector) = flagModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ flag: FlagModelType }>(
          `query flag($id: ID!) { flag(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new FlagModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryFlags(
        variables: { orderBy?: FlagOrderBy; pagination?: PaginationArgs; filters?: FlagFilters },
        resultSelector:
          | string
          | ((
              qb: FlagListResponseModelSelector,
            ) => FlagListResponseModelSelector) = flagListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ flags: FlagListResponseModelType }>(
          `query flags($orderBy: FlagOrderBy, $pagination: PaginationArgs, $filters: FlagFilters) { flags(orderBy: $orderBy, pagination: $pagination, filters: $filters) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new FlagListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryPhoneNumber(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: PhoneNumberModelSelector,
            ) => PhoneNumberModelSelector) = phoneNumberModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ phoneNumber: PhoneNumberModelType }>(
          `query phoneNumber($id: ID!) { phoneNumber(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new PhoneNumberModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryPhoneNumbers(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: PhoneNumberListResponseModelSelector,
            ) => PhoneNumberListResponseModelSelector) = phoneNumberListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ phoneNumbers: PhoneNumberListResponseModelType }>(
          `query phoneNumbers($pagination: PaginationArgs) { phoneNumbers(pagination: $pagination) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new PhoneNumberListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryExport(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: ExportModelSelector) => ExportModelSelector) = exportModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ export: ExportModelType }>(
          `query export($id: ID!) { export(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new ExportModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryExports(
        variables: { pagination?: PaginationArgs; filters?: ExportFilters },
        resultSelector:
          | string
          | ((
              qb: ExportListResponseModelSelector,
            ) => ExportListResponseModelSelector) = exportListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ exports: ExportListResponseModelType }>(
          `query exports($pagination: PaginationArgs, $filters: ExportFilters) { exports(pagination: $pagination, filters: $filters) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new ExportListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryUser(
        variables: { id: string },
        resultSelector:
          | string
          | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ user: UserModelType }>(
          `query user($id: ID!) { user(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new UserModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryUsers(
        variables: { pagination?: PaginationArgs },
        resultSelector:
          | string
          | ((
              qb: UserListResponseModelSelector,
            ) => UserListResponseModelSelector) = userListResponseModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ users: UserListResponseModelType }>(
          `query users($pagination: PaginationArgs) { users(pagination: $pagination) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new UserListResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      queryWhoAmI(
        variables?: {},
        resultSelector:
          | string
          | ((qb: UserModelSelector) => UserModelSelector) = userModelPrimitives.toString(),
        options: QueryOptions = {},
      ) {
        return self.query<{ whoAmI: UserModelType }>(
          `query whoAmI { whoAmI {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new UserModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          options,
        )
      },
      mutateLogin(
        variables: { input: UserLoginArgs },
        resultSelector:
          | string
          | ((
              qb: UserLoginResponseModelSelector,
            ) => UserLoginResponseModelSelector) = userLoginResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ login: UserLoginResponseModelType }>(
          `mutation login($input: UserLoginArgs!) { login(input: $input) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new UserLoginResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateLogout(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ logout: MutationResponseModelType }>(
          `mutation logout { logout {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateRefreshToken(
        variables?: {},
        resultSelector:
          | string
          | ((
              qb: RefreshTokenResponseModelSelector,
            ) => RefreshTokenResponseModelSelector) = refreshTokenResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ refreshToken: RefreshTokenResponseModelType }>(
          `mutation refreshToken { refreshToken {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new RefreshTokenResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateAddress(
        variables: { input: CreateAddressInput },
        resultSelector:
          | string
          | ((
              qb: AddressCreationResponseModelSelector,
            ) => AddressCreationResponseModelSelector) = addressCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createAddress: AddressCreationResponseModelType }>(
          `mutation createAddress($input: CreateAddressInput!) { createAddress(input: $input) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new AddressCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateAddress(
        variables: { input: UpdateAddressInput; id: string },
        resultSelector:
          | string
          | ((
              qb: AddressUpdateResponseModelSelector,
            ) => AddressUpdateResponseModelSelector) = addressUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateAddress: AddressUpdateResponseModelType }>(
          `mutation updateAddress($input: UpdateAddressInput!, $id: ID!) { updateAddress(input: $input, id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new AddressUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteAddress(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteAddress: MutationResponseModelType }>(
          `mutation deleteAddress($id: ID!) { deleteAddress(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateAlias(
        variables: { input: CreateAliasInput },
        resultSelector:
          | string
          | ((
              qb: AliasCreationResponseModelSelector,
            ) => AliasCreationResponseModelSelector) = aliasCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createAlias: AliasCreationResponseModelType }>(
          `mutation createAlias($input: CreateAliasInput!) { createAlias(input: $input) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new AliasCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateAlias(
        variables: { input: UpdateAliasInput; id: string },
        resultSelector:
          | string
          | ((
              qb: AliasUpdateResponseModelSelector,
            ) => AliasUpdateResponseModelSelector) = aliasUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateAlias: AliasUpdateResponseModelType }>(
          `mutation updateAlias($input: UpdateAliasInput!, $id: ID!) { updateAlias(input: $input, id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new AliasUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteAlias(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteAlias: MutationResponseModelType }>(
          `mutation deleteAlias($id: ID!) { deleteAlias(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateClient(
        variables: { input: CreateClientInput },
        resultSelector:
          | string
          | ((
              qb: ClientCreationResponseModelSelector,
            ) => ClientCreationResponseModelSelector) = clientCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createClient: ClientCreationResponseModelType }>(
          `mutation createClient($input: CreateClientInput!) { createClient(input: $input) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new ClientCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateClient(
        variables: { input: UpdateClientInput; id: string },
        resultSelector:
          | string
          | ((
              qb: ClientUpdateResponseModelSelector,
            ) => ClientUpdateResponseModelSelector) = clientUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateClient: ClientUpdateResponseModelType }>(
          `mutation updateClient($input: UpdateClientInput!, $id: ID!) { updateClient(input: $input, id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new ClientUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteClient(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteClient: MutationResponseModelType }>(
          `mutation deleteClient($id: ID!) { deleteClient(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteVet(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteVet: MutationResponseModelType }>(
          `mutation deleteVet($id: ID!) { deleteVet(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateVet(
        variables: { input: CreateVetInput },
        resultSelector:
          | string
          | ((
              qb: VetCreationResponseModelSelector,
            ) => VetCreationResponseModelSelector) = vetCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createVet: VetCreationResponseModelType }>(
          `mutation createVet($input: CreateVetInput!) { createVet(input: $input) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new VetCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateVet(
        variables: { input: UpdateVetInput; id: string },
        resultSelector:
          | string
          | ((
              qb: VetUpdateResponseModelSelector,
            ) => VetUpdateResponseModelSelector) = vetUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateVet: VetUpdateResponseModelType }>(
          `mutation updateVet($input: UpdateVetInput!, $id: String!) { updateVet(input: $input, id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new VetUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateEmail(
        variables: { input: CreateEmailInput },
        resultSelector:
          | string
          | ((
              qb: EmailCreationResponseModelSelector,
            ) => EmailCreationResponseModelSelector) = emailCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createEmail: EmailCreationResponseModelType }>(
          `mutation createEmail($input: CreateEmailInput!) { createEmail(input: $input) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new EmailCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateEmail(
        variables: { input: UpdateEmailInput; id: string },
        resultSelector:
          | string
          | ((
              qb: EmailUpdateResponseModelSelector,
            ) => EmailUpdateResponseModelSelector) = emailUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateEmail: EmailUpdateResponseModelType }>(
          `mutation updateEmail($input: UpdateEmailInput!, $id: ID!) { updateEmail(input: $input, id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new EmailUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteEmail(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteEmail: MutationResponseModelType }>(
          `mutation deleteEmail($id: ID!) { deleteEmail(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateTag(
        variables: { input: CreateTagInput },
        resultSelector:
          | string
          | ((
              qb: TagCreationResponseModelSelector,
            ) => TagCreationResponseModelSelector) = tagCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createTag: TagCreationResponseModelType }>(
          `mutation createTag($input: CreateTagInput!) { createTag(input: $input) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new TagCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateTag(
        variables: { input: UpdateTagInput; id: string },
        resultSelector:
          | string
          | ((
              qb: TagUpdateResponseModelSelector,
            ) => TagUpdateResponseModelSelector) = tagUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateTag: TagUpdateResponseModelType }>(
          `mutation updateTag($input: UpdateTagInput!, $id: ID!) { updateTag(input: $input, id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new TagUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteTag(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteTag: MutationResponseModelType }>(
          `mutation deleteTag($id: ID!) { deleteTag(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteFlag(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteFlag: MutationResponseModelType }>(
          `mutation deleteFlag($id: ID!) { deleteFlag(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateFlag(
        variables: { input: CreateFlagInput },
        resultSelector:
          | string
          | ((
              qb: FlagCreationResponseModelSelector,
            ) => FlagCreationResponseModelSelector) = flagCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createFlag: FlagCreationResponseModelType }>(
          `mutation createFlag($input: CreateFlagInput!) { createFlag(input: $input) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new FlagCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateFlag(
        variables: { input: CreateFlagInput; id: string },
        resultSelector:
          | string
          | ((
              qb: FlagUpdateResponseModelSelector,
            ) => FlagUpdateResponseModelSelector) = flagUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateFlag: FlagUpdateResponseModelType }>(
          `mutation updateFlag($input: CreateFlagInput!, $id: String!) { updateFlag(input: $input, id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new FlagUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreatePhoneNumber(
        variables: { input: CreatePhoneNumberInput },
        resultSelector:
          | string
          | ((
              qb: PhoneNumberCreationResponseModelSelector,
            ) => PhoneNumberCreationResponseModelSelector) = phoneNumberCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createPhoneNumber: PhoneNumberCreationResponseModelType }>(
          `mutation createPhoneNumber($input: CreatePhoneNumberInput!) { createPhoneNumber(input: $input) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new PhoneNumberCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdatePhoneNumber(
        variables: { input: UpdatePhoneNumberInput; id: string },
        resultSelector:
          | string
          | ((
              qb: PhoneNumberUpdateResponseModelSelector,
            ) => PhoneNumberUpdateResponseModelSelector) = phoneNumberUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updatePhoneNumber: PhoneNumberUpdateResponseModelType }>(
          `mutation updatePhoneNumber($input: UpdatePhoneNumberInput!, $id: ID!) { updatePhoneNumber(input: $input, id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new PhoneNumberUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeletePhoneNumber(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deletePhoneNumber: MutationResponseModelType }>(
          `mutation deletePhoneNumber($id: ID!) { deletePhoneNumber(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteExport(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteExport: MutationResponseModelType }>(
          `mutation deleteExport($id: ID!) { deleteExport(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateGenerateExport(
        variables: { filters: FlagFilters; title: string },
        resultSelector:
          | string
          | ((
              qb: GenerateExportMutationResponseModelSelector,
            ) => GenerateExportMutationResponseModelSelector) = generateExportMutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ generateExport: GenerateExportMutationResponseModelType }>(
          `mutation generateExport($filters: FlagFilters!, $title: String!) { generateExport(filters: $filters, title: $title) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new GenerateExportMutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateDeleteUser(
        variables: { id: string },
        resultSelector:
          | string
          | ((
              qb: MutationResponseModelSelector,
            ) => MutationResponseModelSelector) = mutationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ deleteUser: MutationResponseModelType }>(
          `mutation deleteUser($id: ID!) { deleteUser(id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new MutationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateCreateUser(
        variables: { input: CreateUserInput },
        resultSelector:
          | string
          | ((
              qb: UserCreationResponseModelSelector,
            ) => UserCreationResponseModelSelector) = userCreationResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ createUser: UserCreationResponseModelType }>(
          `mutation createUser($input: CreateUserInput!) { createUser(input: $input) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new UserCreationResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
      mutateUpdateUser(
        variables: { input: UpdateUserInput; id: string },
        resultSelector:
          | string
          | ((
              qb: UserUpdateResponseModelSelector,
            ) => UserUpdateResponseModelSelector) = userUpdateResponseModelPrimitives.toString(),
        optimisticUpdate?: () => void,
      ) {
        return self.mutate<{ updateUser: UserUpdateResponseModelType }>(
          `mutation updateUser($input: UpdateUserInput!, $id: ID!) { updateUser(input: $input, id: $id) {
        ${
          typeof resultSelector === "function"
            ? resultSelector(new UserUpdateResponseModelSelector()).toString()
            : resultSelector
        }
      } }`,
          variables,
          optimisticUpdate,
        )
      },
    })),
)
