import MaterialTable, { Column, Options, Query as TableQuery, QueryResult } from "material-table"
import * as React from "react"
import {
  RootStoreType,
  UserListResponseModelSelector,
  UserListResponseModelType,
  userModelPrimitives,
  UserModelType,
} from "../../models"
import { Query } from "mst-gql"
import { rowDatum } from "../../utilities/coercion"
import { extractPaginationArgs } from "../../utilities/tables"
import { useStore } from "../../getMstGql"
import { observer } from "mobx-react-lite"
import { Accordion, AccordionSummary, Typography } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { useTableCacheKey } from "../common/utils/table/useTableCacheKey"

type UsersQueryResponseType = { users: UserListResponseModelType }

const usersSelector = new UserListResponseModelSelector().count
  .records(userModelPrimitives)
  .toString()

function queryUsers(
  store: RootStoreType,
  tableQuery: TableQuery<UserModelType>,
): Query<UsersQueryResponseType> {
  return store.queryUsers({ pagination: extractPaginationArgs(tableQuery) }, usersSelector)
}

export type UsersTableProps = {
  filters?: {}
  tableOptions?: Options<UserModelType>
  onRowClick?: (row: UserModelType) => void
  cacheKey?: string | number
}

const UsersTable: React.FC<UsersTableProps> = ({ onRowClick, cacheKey, tableOptions = {} }) => {
  const store = useStore()
  const tableRef = useTableCacheKey(cacheKey)
  const handleDetailsClick = React.useCallback(
    (row: UserModelType): void => onRowClick && onRowClick(row),
    [onRowClick],
  )

  const handleTableUpdate = React.useCallback(
    async (query: TableQuery<UserModelType>): Promise<QueryResult<UserModelType>> => {
      const results = await queryUsers(store, query)
      return {
        data: results?.users?.records?.toJS() || [],
        totalCount: results?.users?.count || 0,
        page: query.page,
      }
    },
    [store],
  )

  const combinedTableOptions: Options<UserModelType> = React.useMemo(
    () => ({
      filtering: false,
      sorting: false,
      toolbar: false,
      debounceInterval: 300,
      pageSize: 10,
      ...tableOptions,
    }),
    [tableOptions],
  )

  const columns = React.useMemo(
    (): Array<Column<UserModelType>> => [
      { title: "Email", field: "email" },
      { title: "Name", field: "name" },
      { title: "Role", field: "role" },
    ],
    [],
  )

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Users</Typography>
      </AccordionSummary>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={handleTableUpdate}
        options={combinedTableOptions}
        localization={{ header: { actions: "" } }}
        style={{ boxShadow: "none" }}
        actions={[
          {
            icon: "pageview",
            tooltip: "View details",
            onClick: (event, d): void => handleDetailsClick(rowDatum(d)),
          },
        ]}
      />
    </Accordion>
  )
}

export default observer(UsersTable)
