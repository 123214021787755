/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"

/**
 * TagBase
 * auto generated base class for the model TagModel.
 */
export const TagModelBase = ModelBase.named("Tag")
  .props({
    __typename: types.optional(types.literal("Tag"), "Tag"),
    id: types.identifier,
    createdAt: types.union(types.undefined, types.frozen()),
    updatedAt: types.union(types.undefined, types.frozen()),
    name: types.union(types.undefined, types.string),
  })
  .views((self) => ({
    get store() {
      return self.__getStore<RootStoreType>()
    },
  }))

export class TagModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get name() {
    return this.__attr(`name`)
  }
}
export function selectFromTag() {
  return new TagModelSelector()
}

export const tagModelPrimitives = selectFromTag().createdAt.updatedAt.name
