import { Box, BoxProps, CircularProgress } from "@material-ui/core"
import React from "react"

export type LoadingOverlayProps = BoxProps & {
  loading?: boolean
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ loading, ...props }) => {
  return loading ? (
    <Box
      display="flex"
      justifyContent="center"
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      zIndex={9999}
      {...props}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        style={{ opacity: 0.5 }}
        bgcolor="black"
      >
        <CircularProgress />
      </Box>
    </Box>
  ) : null
}

export default LoadingOverlay
