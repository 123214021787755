/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { StateEnumType } from "./StateEnum"
import { VetModel, VetModelType } from "./VetModel"
import { VetModelSelector } from "./VetModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  vet: VetModelType
}

/**
 * AddressBase
 * auto generated base class for the model AddressModel.
 */
export const AddressModelBase = withTypedRefs<Refs>()(
  ModelBase.named("Address")
    .props({
      __typename: types.optional(types.literal("Address"), "Address"),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      state: types.union(types.undefined, StateEnumType),
      streetAddress: types.union(types.undefined, types.string),
      city: types.union(types.undefined, types.string),
      vetId: types.union(types.undefined, types.string),
      vet: types.union(types.undefined, MSTGQLRef(types.late((): any => VetModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class AddressModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get state() {
    return this.__attr(`state`)
  }
  get streetAddress() {
    return this.__attr(`streetAddress`)
  }
  get city() {
    return this.__attr(`city`)
  }
  get vetId() {
    return this.__attr(`vetId`)
  }
  vet(builder?: string | VetModelSelector | ((selector: VetModelSelector) => VetModelSelector)) {
    return this.__child(`vet`, VetModelSelector, builder)
  }
}
export function selectFromAddress() {
  return new AddressModelSelector()
}

export const addressModelPrimitives = selectFromAddress().createdAt.updatedAt.state.streetAddress
  .city.vetId
