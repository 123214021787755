import { Query as TableQuery, Query } from "material-table"
import { OrderByDirection } from "src/models/OrderByDirectionEnum"
import { PaginationArgs } from "../models/RootStore.base"
import { Maybe } from "./types"

export function extractPaginationArgs(query: TableQuery<any>): PaginationArgs {
  return {
    limit: query.pageSize,
    offset: query.pageSize * query.page,
  }
}

export interface OrderByArgs<T> {
  column: T
  direction: OrderByDirection
}

export function parseOrderByDirection(value: string): OrderByDirection {
  switch (value.toLowerCase()) {
    case "asc":
      return OrderByDirection.Asc
    case "desc":
      return OrderByDirection.Desc
    default:
      throw new Error(`Failed to parse OrderByDirection from ${value}`)
  }
}

export const extractOrderByArgs = <T extends string>(
  query: Query<any>,
  columnEnum: Record<string, T>,
): Maybe<OrderByArgs<T>> => {
  if (query.orderBy?.field) {
    const column = Object.values(columnEnum).find(
      (col) => col.toLowerCase() === query.orderBy.field?.toString().toLowerCase(),
    )
    if (!column) {
      const errorMessage = `Could not parse ${query.orderBy?.field.toString()} as OrderByColumn ${Object.values(
        columnEnum,
      ).join(", ")}`
      console.error(errorMessage)
      throw new Error(errorMessage)
    }
    return {
      column,
      direction: parseOrderByDirection(query.orderDirection),
    }
  }
  return
}
