/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum FlagOrderColumns {
  CompletedAt = "CompletedAt",
  Title = "Title",
  Text = "Text",
  Source = "Source",
}

/**
 * FlagOrderColumns
 */
export const FlagOrderColumnsEnumType = types.enumeration("FlagOrderColumns", [
  "CompletedAt",
  "Title",
  "Text",
  "Source",
])
