import { Button } from "@material-ui/core"
import { Formik, FormikProps } from "formik"
import * as React from "react"
import * as Yup from "yup"
import { useStore } from "../../getMstGql"
import { AliasModelType, EntityType } from "../../models"
import { displayMutationError, hasMutationErrors } from "../../utilities/errors"
import { useToast } from "../common"
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormTextField,
} from "../common/forms"

type RecordType = AliasModelType

function getInitialValues(record?: RecordType) {
  return {
    firstName: record?.firstName ?? "",
    lastName: record?.lastName ?? "",
    middleName: record?.middleName ?? "",
  }
}

const schema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string(),
  middleName: Yup.string(),
})

export interface AliasFormProps {
  onComplete: (updated?: boolean) => any
  vetId: string
  record?: RecordType
  entityType: EntityType
}

const AliasForm: React.FC<AliasFormProps> = ({ entityType, onComplete, vetId, record }) => {
  const store = useStore()
  const { setToast } = useToast()

  return (
    <FormContainer title={record ? "Edit Address" : "Add Address"}>
      <Formik
        initialValues={getInitialValues(record)}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          try {
            actions.setSubmitting(true)

            const response = record
              ? await store
                  .mutateUpdateAlias({
                    id: record.id,
                    input: {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      middleName: values.middleName,
                      vetId,
                    },
                  })
                  .currentPromise()
              : await store
                  .mutateCreateAlias({
                    input: {
                      firstName: values.firstName,
                      lastName: values.lastName,
                      middleName: values.middleName,
                      vetId,
                    },
                  })
                  .currentPromise()

            if (hasMutationErrors(response)) {
              setToast(displayMutationError(response))
              return
            }

            setToast({
              message: `Alias has been ${record ? "updated" : "added"}!`,
              variant: "success",
            })
            actions.resetForm()
            onComplete(true)
          } finally {
            actions.setSubmitting(false)
          }
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            {entityType === EntityType.Person ? (
              <FormSection title="">
                <FieldGroup>
                  <FormTextField label="First name" name="firstName" />
                  <FormTextField label="Middle name" name="middleName" />
                  <FormTextField label="Last name" name="lastName" />
                </FieldGroup>
              </FormSection>
            ) : (
              <FormSection title="">
                <FieldGroup>
                  <FormTextField label="Name" name="firstName" />
                </FieldGroup>
              </FormSection>
            )}

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                onClick={() => {
                  return props.submitForm()
                }}
              >
                Save
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default AliasForm
