import { Button } from "@material-ui/core"
import { Formik, FormikProps } from "formik"
import * as React from "react"
import { EntityType, State } from "../../models"
import { VetFilters } from "../../models/RootStore.base"
import { formatState } from "../../utilities/states"
import { FormSelectField } from "../common"
import { FieldGroup, FormButtonGroup, FormSection, FormTextField } from "../common/forms"

type RecordType = VetFilters

function getInitialValues(record?: RecordType) {
  return {
    entityType: record?.entityType ?? null,
    firstName: record?.firstName ?? null,
    lastName: record?.lastName ?? null,
    middleName: record?.middleName ?? null,
    email: record?.email ?? null,
    streetAddress: record?.streetAddress ?? null,
    state: record?.state ?? null,
    city: record?.city ?? null,
    phone: record?.phone ?? null,
  }
}

export interface VetFiltersFormProps {
  filters?: VetFilters
  onChange: (filters: VetFilters) => any
}

const VetFiltersForm: React.FC<VetFiltersFormProps> = ({ filters, onChange }) => {
  return (
    <Formik
      initialValues={getInitialValues(filters)}
      validateOnBlur={false}
      onSubmit={async (values) => {
        onChange({
          entityType: values.entityType ?? undefined,
          firstName: values.firstName ?? undefined,
          lastName: values.lastName ?? undefined,
          middleName: values.middleName ?? undefined,
          email: values.email ?? undefined,
          streetAddress: values.streetAddress ?? undefined,
          state: values.state ?? undefined,
          city: values.city ?? undefined,
          phone: values.phone ?? undefined,
        })
      }}
    >
      {({ values, resetForm, submitForm }: FormikProps<any>) => (
        <form
          onSubmit={(evt) => {
            evt.preventDefault()
            submitForm()
          }}
        >
          <FormSection title="Name" typeVariant="subtitle1">
            <FieldGroup>
              <FormSelectField
                label="Entity type"
                name="entityType"
                options={Object.values(EntityType).map((value) => ({
                  value,
                  label: value,
                }))}
                clearable
                onChange={() => {
                  resetForm()
                }}
              />
              {values.entityType === EntityType.Person ? (
                <>
                  <FormTextField label="First name" name="firstName" />
                  <FormTextField label="Middle name" name="middleName" />
                  <FormTextField label="Last name" name="lastName" />
                </>
              ) : (
                <FormTextField label="Name" name="firstName" />
              )}
            </FieldGroup>
          </FormSection>

          <FormSection title="Address" typeVariant="subtitle1">
            <FieldGroup>
              <FormTextField label="Street" name="streetAddress" />
              <FormTextField label="City" name="city" />
              <FormSelectField
                label="State"
                name="state"
                options={Object.values(State).map((value) => ({
                  value: value,
                  label: formatState(value),
                }))}
                clearable
              />
            </FieldGroup>
          </FormSection>

          <FormSection title="Contact" typeVariant="subtitle1">
            <FieldGroup>
              <FormTextField label="Phone" name="phone" />
              <FormTextField label="Email" name="email" />
            </FieldGroup>
          </FormSection>

          <FormButtonGroup>
            <Button
              variant="contained"
              onClick={() => {
                resetForm()
                onChange({})
              }}
            >
              Reset
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => {
                return submitForm()
              }}
            >
              Search
            </Button>
          </FormButtonGroup>
        </form>
      )}
    </Formik>
  )
}

export default VetFiltersForm
