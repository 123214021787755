import { Accordion, AccordionSummary, IconButton, Tooltip, Typography } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import PageviewIcon from "@material-ui/icons/Pageview"
import MaterialTable, { Column, Options, Query as TableQuery, QueryResult } from "material-table"
import { observer } from "mobx-react-lite"
import { Query } from "mst-gql"
import * as React from "react"
import { Link } from "react-router-dom"
import { useStore } from "../../getMstGql"
import { RootStoreType, VetListResponseModelType, VetModelType } from "../../models"
import { VetFilters } from "../../models/RootStore.base"
import { vetsListSelector } from "../../models/selectors"
import { extractPaginationArgs } from "../../utilities/tables"
import { useTableCacheKey } from "../common/utils/table/useTableCacheKey"

type QueryResponseType = { vets: VetListResponseModelType }

function buildQuery(
  store: RootStoreType,
  tableQuery: TableQuery<VetModelType>,
  filters?: VetFilters,
): Query<QueryResponseType> {
  return store.queryVets(
    {
      filters,
      pagination: extractPaginationArgs(tableQuery),
    },
    vetsListSelector,
  )
}

export type VetsTableProps = {
  filters?: VetFilters
  tableOptions?: Options<VetModelType>
  cacheKey?: any
}

const VetsTable: React.FC<VetsTableProps> = ({ filters, cacheKey, tableOptions = {} }) => {
  const store = useStore()
  const tableRef = useTableCacheKey(cacheKey)

  const handleTableUpdate = React.useCallback(
    async (query: TableQuery<VetModelType>): Promise<QueryResult<VetModelType>> => {
      const results = await buildQuery(store, query, filters)
      return {
        data: results?.vets?.records?.toJS() || [],
        totalCount: results?.vets?.count || 0,
        page: query.page,
      }
    },
    [filters, store],
  )

  const combinedTableOptions: Options<VetModelType> = React.useMemo(
    () => ({
      filtering: false,
      toolbar: false,
      debounceInterval: 300,
      pageSize: 10,
      ...tableOptions,
    }),
    [tableOptions],
  )

  const columns = React.useMemo(
    (): Array<Column<VetModelType>> => [
      {
        title: "",
        width: 50,
        render: (data: VetModelType): React.ReactElement => {
          return (
            <Tooltip title="View vet details">
              <Link to={`/vets/${data.id}`}>
                <IconButton {...({ variant: "contained" } as any)}>
                  <PageviewIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )
        },
      },
      {
        title: "Name",
        field: "name",
      },
      {
        title: "Flags",
        field: "flagCount",
      },
    ],
    [],
  )

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Vets</Typography>
      </AccordionSummary>
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={handleTableUpdate}
        options={combinedTableOptions}
        localization={{ header: { actions: "" } }}
        style={{ boxShadow: "none" }}
      />
    </Accordion>
  )
}

export default observer(VetsTable)
