import * as React from "react"
import { makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    "& button": {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      "&:first-child": {
        marginLeft: 0,
      },
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
}))

export interface ActionButtonContainerProps {
  children: React.ReactNode
}

const ActionButtonContainer: React.FC<ActionButtonContainerProps> = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.buttonContainer}>{children}</div>
}

export default ActionButtonContainer
