import { makeStyles, Theme } from "@material-ui/core"
import * as React from "react"

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    flex: 1,
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    marginTop: theme.spacing(4),
    "& button": {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      "&:first-child": {
        marginLeft: 0,
      },
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
}))

export interface FormButtonGroupProps {
  children: JSX.Element | Array<JSX.Element>
}

const FormButtonGroup: React.FC<FormButtonGroupProps> = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.buttonContainer}>{children}</div>
}

export default FormButtonGroup
