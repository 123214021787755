import { Button } from "@material-ui/core"
import { Formik, FormikProps } from "formik"
import * as React from "react"
import { useHistory } from "react-router-dom"
import * as Yup from "yup"
import { useStore } from "../../getMstGql"
import { FlagFilters } from "../../models/RootStore.base"
import { displayMutationError, hasMutationErrors } from "../../utilities/errors"
import { useToast } from "../common"
import {
  FieldGroup,
  FormButtonGroup,
  FormContainer,
  FormSection,
  FormTextField,
} from "../common/forms"

const schema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
})

export interface GenerateExportFormProps {
  onComplete: (willNavigate?: boolean) => any
  filters: FlagFilters
  defaultTitle?: string
  redirectOnCreate?: boolean
}

const GenerateExportForm: React.FC<GenerateExportFormProps> = ({
  onComplete,
  filters,
  defaultTitle,
  redirectOnCreate = true,
}) => {
  const history = useHistory()
  const store = useStore()
  const { setToast } = useToast()

  return (
    <FormContainer title="Generate Export">
      <Formik
        initialValues={{
          title: defaultTitle ?? "",
        }}
        validationSchema={schema}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)

          const response = await store
            .mutateGenerateExport({ filters, title: values.title })
            .currentPromise()

          if (hasMutationErrors(response)) {
            setToast(displayMutationError(response))
            return
          }

          setToast({
            message: `Export ${values.title} has been created!`,
            variant: "success",
          })
          actions.resetForm()
          onComplete()
          if (redirectOnCreate) {
            history.push("/exports")
          }
        }}
      >
        {(props: FormikProps<any>) => (
          <React.Fragment>
            <FormSection title="">
              <FieldGroup>
                <FormTextField label="Title" name="title" />
              </FieldGroup>
            </FormSection>

            <FormButtonGroup>
              <Button
                variant="contained"
                onClick={() => {
                  props.resetForm()
                  onComplete()
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={props.isSubmitting}
                onClick={() => {
                  return props.submitForm()
                }}
              >
                Export
              </Button>
            </FormButtonGroup>
          </React.Fragment>
        )}
      </Formik>
    </FormContainer>
  )
}

export default GenerateExportForm
