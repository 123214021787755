import { Field, FieldProps } from "formik"
import React from "react"
import { TextInput } from "./index"
import { TextInputProps } from "./TextInput"
import { makeStyles } from "@material-ui/core/styles"
import { Box, BoxProps, Theme } from "@material-ui/core"
import capitalize from "lodash/capitalize"

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginRight: 0,
      marginBottom: 0,
    },
  },
}))

export type TextFormFieldProps = TextInputProps & {
  name: string
  label?: string
  containerProps?: BoxProps
  type?: string
  placeholder?: string
}

const TextFormField: React.FC<TextFormFieldProps> = ({
  name,
  label,
  placeholder,
  containerProps,
  type,
  ...props
}) => {
  const styles = useStyles()
  const inputLabel = React.useMemo(() => label || capitalize(name), [label, name])
  return (
    <Box width="100%" className={styles.container} {...containerProps}>
      <Field name={name}>
        {({ field, meta }: FieldProps): React.ReactNode => {
          const isInvalid = Boolean(meta.touched && meta.error)
          return (
            <TextInput
              id={name}
              error={isInvalid}
              helperText={isInvalid && meta.error}
              type={type}
              label={inputLabel}
              placeholder={placeholder}
              {...field}
              {...props}
            />
          )
        }}
      </Field>
    </Box>
  )
}

export default TextFormField
