/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ClientModel, ClientModelType } from "./ClientModel"
import { ClientModelSelector } from "./ClientModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  client: ClientModelType
}

/**
 * ClientUpdateResponseBase
 * auto generated base class for the model ClientUpdateResponseModel.
 */
export const ClientUpdateResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("ClientUpdateResponse")
    .props({
      __typename: types.optional(types.literal("ClientUpdateResponse"), "ClientUpdateResponse"),
      success: types.union(types.undefined, types.boolean),
      message: types.union(types.undefined, types.string),
      client: types.union(
        types.undefined,
        types.null,
        MSTGQLRef(types.late((): any => ClientModel)),
      ),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class ClientUpdateResponseModelSelector extends QueryBuilder {
  get success() {
    return this.__attr(`success`)
  }
  get message() {
    return this.__attr(`message`)
  }
  client(
    builder?:
      | string
      | ClientModelSelector
      | ((selector: ClientModelSelector) => ClientModelSelector),
  ) {
    return this.__child(`client`, ClientModelSelector, builder)
  }
}
export function selectFromClientUpdateResponse() {
  return new ClientUpdateResponseModelSelector()
}

export const clientUpdateResponseModelPrimitives = selectFromClientUpdateResponse().success.message
