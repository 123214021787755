/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum VetOrderColumns {
  FirstName = "FirstName",
}

/**
 * VetOrderColumns
 */
export const VetOrderColumnsEnumType = types.enumeration("VetOrderColumns", ["FirstName"])
