/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { VetModel, VetModelType } from "./VetModel"
import { VetModelSelector } from "./VetModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  vet: VetModelType
}

/**
 * PhoneNumberBase
 * auto generated base class for the model PhoneNumberModel.
 */
export const PhoneNumberModelBase = withTypedRefs<Refs>()(
  ModelBase.named("PhoneNumber")
    .props({
      __typename: types.optional(types.literal("PhoneNumber"), "PhoneNumber"),
      id: types.identifier,
      createdAt: types.union(types.undefined, types.frozen()),
      updatedAt: types.union(types.undefined, types.frozen()),
      number: types.union(types.undefined, types.string),
      vetId: types.union(types.undefined, types.string),
      vet: types.union(types.undefined, MSTGQLRef(types.late((): any => VetModel))),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class PhoneNumberModelSelector extends QueryBuilder {
  get id() {
    return this.__attr(`id`)
  }
  get createdAt() {
    return this.__attr(`createdAt`)
  }
  get updatedAt() {
    return this.__attr(`updatedAt`)
  }
  get number() {
    return this.__attr(`number`)
  }
  get vetId() {
    return this.__attr(`vetId`)
  }
  vet(builder?: string | VetModelSelector | ((selector: VetModelSelector) => VetModelSelector)) {
    return this.__child(`vet`, VetModelSelector, builder)
  }
}
export function selectFromPhoneNumber() {
  return new PhoneNumberModelSelector()
}

export const phoneNumberModelPrimitives = selectFromPhoneNumber().createdAt.updatedAt.number.vetId
