import { observer } from "mobx-react-lite"
import React, { useMemo } from "react"
import { useStore } from "../../../getMstGql"
import { UserRole } from "../../../models"

export type RequiresRoleProps = {
  role: UserRole
}

const RequiresRole: React.FC<RequiresRoleProps> = ({ children, role }) => {
  const { authentication } = useStore()
  const hasRole: boolean = useMemo(() => authentication.hasRole(role), [authentication, role])
  return hasRole ? <React.Fragment>{children}</React.Fragment> : <div />
}

export default observer(RequiresRole)
