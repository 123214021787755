import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core/styles"
import { LocalizationProvider } from "@material-ui/pickers"
import LuxonAdapter from "@material-ui/pickers/adapter/luxon"
import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { AppInitializer } from "src/components"
import { defaultTheme } from "./constants/theme"
import { rootStore } from "./getMstGql"
import { StoreContext } from "./models/reactUtils"
import Routes from "./Routes"

const App: React.FC = () => (
  <StoreContext.Provider value={rootStore}>
    <AppInitializer>
      <ThemeProvider theme={defaultTheme}>
        <LocalizationProvider dateAdapter={LuxonAdapter}>
          <CssBaseline />
          <Router>
            <Routes />
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </AppInitializer>
  </StoreContext.Provider>
)

export default App
