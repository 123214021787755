/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { EmailModel, EmailModelType } from "./EmailModel"
import { EmailModelSelector } from "./EmailModel.base"
import { RootStoreType } from "./index"

/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  records: IObservableArray<EmailModelType>
}

/**
 * EmailListResponseBase
 * auto generated base class for the model EmailListResponseModel.
 */
export const EmailListResponseModelBase = withTypedRefs<Refs>()(
  ModelBase.named("EmailListResponse")
    .props({
      __typename: types.optional(types.literal("EmailListResponse"), "EmailListResponse"),
      records: types.union(
        types.undefined,
        types.array(MSTGQLRef(types.late((): any => EmailModel))),
      ),
      count: types.union(types.undefined, types.number),
    })
    .views((self) => ({
      get store() {
        return self.__getStore<RootStoreType>()
      },
    })),
)

export class EmailListResponseModelSelector extends QueryBuilder {
  get count() {
    return this.__attr(`count`)
  }
  records(
    builder?: string | EmailModelSelector | ((selector: EmailModelSelector) => EmailModelSelector),
  ) {
    return this.__child(`records`, EmailModelSelector, builder)
  }
}
export function selectFromEmailListResponse() {
  return new EmailListResponseModelSelector()
}

export const emailListResponseModelPrimitives = selectFromEmailListResponse().count
